/* eslint-disable max-len */
import React from "react";
// import { useSelector, useDispatch } from "react-redux";
import { FaTimes, FaCheck } from "react-icons/fa";
import { Button } from "../../../../ui/components";

import "./student-subscription.m.css";
// import PricingTabs from "./PricingTabs";
import { Tabs } from "../../../../ui/components/tabs/Tabs";
// import { parseUrl } from "query-string";
// import { get } from "lodash";
// import Axios from "axios";
// import { SELECTED_PLAN } from "../../../helper/actions";
// import { getIntegrationToken } from "../../../helper/api";
// import { Link } from "@quintype/components";

const data = {
  duration: "One Year Subscription",
  description: [
    {
      isApplicable: true,
      details: "Paywall stories",
    },
    // {
    //   isApplicable: true,
    //   details: "Subscriber-only Discord server"
    // },
    {
      isApplicable: true,
      details: "Meet-ups and virtual hangouts: NL Recess, NL Chatbox",
    },
    {
      isApplicable: true,
      details: "Subscriber-only events, including The Media Rumble",
    },
  ],
  planName: "NL-TNM Joint Disruptor: One Year",
};

// const pricingData = [
//   {
//     title: "NL-TNM Joint Disruptor: One Month",
//     discountedPrice: "₹ 249",
//     actualPrice: "₹ 499",
//     imageUrl:
//       "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png",
//     description: [
//       { details: "Paywall stories", isApplicable: true },
//       {
//         details: "Meet-ups and virtual hangouts: NL Recess, NL Chatbox",
//         isApplicable: true,
//       },
//       {
//         details: "Subscriber-only events, including The Media Rumble",
//         isApplicable: true,
//       },
//     ],
//     formLink: "https://forms.gle/6r5TNW5d58HbTv1p7",
//   },
//   {
//     title: "NL-TNM Joint Disruptor: One Year",
//     discountedPrice: "₹ 2499",
//     actualPrice: "₹ 4999",
//     imageUrl:
//       "https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png",
//     description: [
//       { details: "Paywall stories", isApplicable: true },
//       {
//         details: "Meet-ups and virtual hangouts: NL Recess, NL Chatbox",
//         isApplicable: true,
//       },
//       {
//         details: "Subscriber-only events, including The Media Rumble",
//         isApplicable: true,
//       },
//     ],
//     formLink: "https://forms.gle/DKCN1u8CSeNoMTVQ9",
//   },
// ];

const StudentSubscription = (props) => {
  // const { member, qt } = useSelector(state => state);
  // const dispatch = useDispatch();

  // const subscriptionGroup =
  //   subscriptions && subscriptions.find(plan => plan.id === 114);
  // const subscriptionPlan =
  //   subscriptionGroup &&
  //   subscriptionGroup.subscription_plans.find(plan => plan.id === 2033);

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  // const navigateTo = url => global.app.navigateToPage(dispatch, url);
  // const selectedPlan = redirectUrl => {
  //   dispatch({
  //     type: SELECTED_PLAN,
  //     plan: subscriptionPlan,
  //     planType: "standard",
  //     redirectUrl: redirectUrl,
  //     subscriptionPlans: subscriptionGroup.subscription_plans
  //   });
  // };

  // <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_KmwgQV5bO0nIUY" async> </script> </form>

  // const params = parseUrl(qt.currentPath);
  // const authCode = params.query.code;

  // const buttonText = authCode ? "Subscribe Now" : "Yes, I'm a student";

  const handleBuyNow = (planId) => () => {
    window.open(`https://razorpay.com/payment-button/${planId}/view`, "_blank");
  };

  // const handleSubscribeBtnClick = async () => {
  //   setLoading(true);

  //   if (!member) {
  //     const callbackUrl = get(
  //       params,
  //       ["query", "callback-url"],
  //       global.location && global.location.origin
  //     );
  //     const redirectUrl = get(
  //       params,
  //       ["query", "redirect-url"],
  //       global && global.location && global.location.href
  //     );

  //     setLoading(false);
  //     window.location = `${qt.config.publisher.publisher.auth_host}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${qt.config.publisher.publisher.auth_host}/user-login`;

  //     return false;
  //   }

  //   if (!authCode) {
  //     window.open(
  //       `https://app.studentidentify.com/?oauth=true&clientId=499e1f1098d0533f49fe8c767db46e2e448e58f7&response_type=code&redirect_uri=https://www.newslaundry.com/student-subscription&scope=status,basic_info,mobile&state=${member.id}`
  //     );

  //     setLoading(false);
  //     return false;
  //   } else {
  //     try {
  //       const token = await getIntegrationToken();

  //       const {
  //         data: { studentId }
  //       } = await Axios.get(
  //         `/check-student-subscription?authCode=${authCode}&integrationToken=${token}`
  //       );

  //       // const studentId = "hello";

  //       // const redirectUrl = "/student-subscription";

  //       // selectedPlan(redirectUrl);
  //       navigateTo(`/subscription/checkout?plan=2033&code=${studentId}`);
  //       // navigateTo(`/payment-checkout?code=${studentId}`);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //       setError(
  //         error.response && error.response.data.message
  //           ? error.response.data.message
  //           : error.message
  //       );
  //     }
  //   }
  // };

  return (
    <div styleName="section-wrapper">
      <div styleName="hero-section">
        <div styleName="heading-wrapper">
          <div styleName="title-wrapper">
            <h1 styleName="title">Student Subscription</h1>
            <p styleName="border-bottom"></p>
          </div>
          <p styleName="page-description">
            As a subscription-based news organisation, we firmly believe that
            readers need to pay to keep news free from any advertiser influence.
            We were the first movers and our subscribers disrupted the news
            ecosystem with their contributions, paving the way for other news
            organisations to try out this model.
          </p>
          <p styleName="page-description">
            That said, we do not want students to miss out on information. If
            you’re a student, we offer a 50% discount on annual subscriptions
          </p>
          <p styleName="page-description">
            Click on the red button to avail the discount. You will be asked to
            authenticate with your student id. Post authentication (the process
            may take upto a day) , you will receive an email with instructions
            on how to get the discounted subscription.
          </p>
          <p styleName="page-description">
            If you can’t afford the discounted subscription, sign up{" "}
            <a
              href="https://forms.gle/9EZZPZkDoH9SuLdaA"
              target="_blank"
              rel="noopener noreferrer"
              styleName="sponsor-link"
            >
              here
            </a>{" "}
            to get a subscription sponsored by our subscribers. We are only able
            to give a few every month.
          </p>
        </div>
        <div styleName="feature-section">
          <h3 styleName="feature-headline">
            Help us power student subscription
          </h3>
          <p styleName="feature-text">
            Your contribution can directly help students get a better
            understanding of the media, and access information that can help
            them make informed voters and citizens.
          </p>
          {/* <Button variant="accent" onClick={handleBuyNow("pl_Q2Ka3vxx9yeMqL")}>
            Sponsor Student Subscription: One Month
          </Button> */}
          <Button variant="accent" onClick={handleBuyNow("pl_Q2KfRRamrPtf2A")}>
            Sponsor Student Subscription
          </Button>
          {/* <button
            onClick={handleBuyNow("pl_KmwgQV5bO0nIUY")}
            styleName="pay-btn"
          >
            Sponsor Student Subscription
          </button> */}
        </div>
      </div>
      {/* <PricingTabs /> */}
      <div styleName="pricing-wrapper">
        <div styleName="pricing-plan-tag">Save 50%</div>
        <div styleName="pricing-details-wrapper">
          <div styleName="pricing-plan-details">
            <p>{data.planName}</p>
            <div styleName="pricing-plan-price">
              <p aria-label="discounted price">&#8377; 2499</p>
              <p aria-label="Actual price">&#8377; 4999</p>
            </div>
            <img
              src="https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
              alt="Student Subscription"
            />
          </div>
          <ul
            aria-label="plan benefits"
            styleName="pricing-plan-details-wrapper"
          >
            {data.description.map((item, i) => (
              <li styleName="pricing-plan-detail" key={i}>
                <span aria-hidden="true">
                  {item.isApplicable ? (
                    <FaCheck color="#22c55e" />
                  ) : (
                    <FaTimes color="#ef4444" />
                  )}
                </span>
                <p>{item.details}</p>
              </li>
            ))}
          </ul>

          <a
            href="https://forms.gle/sKDkvqCP1PMAZFRs5"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="success" style={{ width: "100%" }}>
              Yes, I&apos;m a student
            </Button>
          </a>
        </div>
      </div>

      {/* <Tabs defaultValue="monthly" styleName="pricing-wrapper">
        <Tabs.List>
          <Tabs.Trigger value="monthly">One-Month</Tabs.Trigger>
          <Tabs.Trigger value="yearly">One-Year</Tabs.Trigger>
        </Tabs.List>

        {pricingData.map((data, index) => (
          <Tabs.Content key={index} value={index === 0 ? "monthly" : "yearly"}>
            <div styleName="pricing-wrapper">
              <div styleName="pricing-plan-tag">Save 50%</div>
              <div styleName="pricing-details-wrapper">
                <div styleName="pricing-plan-details">
                  <p>{data.title}</p>
                  <div styleName="pricing-plan-price">
                    <p aria-label="discounted price">{data.discountedPrice}</p>
                    <p aria-label="Actual price">{data.actualPrice}</p>
                  </div>
                  <img src={data.imageUrl} alt="Subscription Plan" />
                </div>
                <ul
                  aria-label="plan benefits"
                  styleName="pricing-plan-details-wrapper"
                >
                  {data.description.map((item, i) => (
                    <li styleName="pricing-plan-detail" key={i}>
                      <span aria-hidden="true">
                        {item.isApplicable ? (
                          <FaCheck color="#22c55e" />
                        ) : (
                          <FaTimes color="#ef4444" />
                        )}
                      </span>
                      <p>{item.details}</p>
                    </li>
                  ))}
                </ul>
                <a
                  href={data.formLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="success" style={{ width: "100%" }}>
                    Yes, I&apos;m a student
                  </Button>
                </a>
              </div>
            </div>
          </Tabs.Content>
        ))}
      </Tabs> */}
    </div>
  );
};

export { StudentSubscription };
