/* eslint-disable react/prop-types */
import { AccessType, Link, ResponsiveImage } from "@quintype/components";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { StandaloneSenaContainer } from "./standalone-sena-container";
import IntegrationData from "../../../../../config/integrations";
import {
  dateFormatterWithoutTime,
  getHeroImage,
  getStoryMetadata
} from "../../../utils";
import ReactTimeago from "react-timeago";
import Authors from "../../atoms/authors";

import "./election-fund.m.css";
import { BsGiftFill, BsNewspaper } from "react-icons/bs";
import { BiPhoneCall } from "react-icons/bi";
import { COMPRESS_PARAMS_EXTRA_SMALL } from "../../../image-constants";

const ElectionFund2024Base = props => {
  const state = useSelector(state => state);
  const storyItems = get(props, ["data", "collection", "items"], []);
  const storyItems2 = get(props, ["data"], {});
  const config = get(props, ["config"], {});
  const collection = get(props, ["data", "collection"], {});
  const collections = collection.items.filter(
    collection => collection.type === "collection"
  );
  const firstCollectionStories =
    collection.items.filter(collection => collection.type === "story") || [];
  const secondCollection = collections[0];
  const collectionOfCollection =
    (secondCollection &&
      secondCollection.items.filter(
        collection => collection.type === "collection"
      )) ||
    [];

  const stories = storyItems
    .filter(s => s.type === "story")
    .sort(
      (a, b) =>
        new Date(b.story["published-at"]) - new Date(a.story["published-at"])
    )
    .slice(0, 6);
  const coll = storyItems.filter(s => s.type === "collection");

  // console.log(stories);

  const [activeSenaCampaigns, setActiveSenaCampaigns] = useState([]);
  const [sortedCampaigns, setSortedCampaigns] = useState([]);
  const CollectionData = [
    {
      imgUrl:
        "newslaundry%2F2023-05%2F991d6741-6c1c-43ae-bd56-4c53e029e960%2Fmorning_show_1_1_.jpg?format=webp&w=360&dpr=2.0",
      title: "Another Election Show",
      url: "https://www.newslaundry.com/another-election-show"
    },
    {
      imgUrl:
        "newslaundry/2024-04/c8f1a20d-eab8-4358-aefe-b015dbe4ef51/idea_of_india.jpg",
      title: "Idea of India",
      url: "https://www.newslaundry.com/nl-tnm-2024-election-fund/idea-of-india"
    },
    {
      imgUrl:
        "newslaundry/2024-02/9403cbab-08f9-455d-a431-ecdc99fb78aa/women_voters__1_.jpg",
      title: "Gendered Polls",
      url: "https://www.newslaundry.com/nl-tnm-2024-election-fund/gendered-polls"
    },
    {
      imgUrl:
        "newslaundry/2024-02/2e941d6d-a6a7-40bd-b1f3-c3e25fdf7057/battleground_states.jpg",
      title: "Battleground States",
      url: "https://www.newslaundry.com/nl-tnm-2024-election-fund/battleground-states"
    },
    {
      imgUrl:
        "newslaundry%2F2024-04%2F02d80e65-f2cf-407d-b5e7-5c4bcedc9bd5%2Fmandate_2024_thumbnail1__1_.jpg?format=webp&w=360&dpr=2.0",
      title: "Mandate 2024: Claim vs Reality",
      url: "https://www.newslaundry.com/tv/mandate-2024-claim-vs-reality"
    },
    {
      imgUrl:
        "newslaundry/2024-01/cc8968ca-c4c5-4f37-94c5-2d28b8a04b66/NLsenaModi10years.png",
      title: "Modi 2.0 Report Card",
      url: "https://www.newslaundry.com/nl-sena/modi-20-report-card"
    }
  ];

  // console.log({ ElectionFund2024Props: props });
  // console.log({ ElectionFund2024State: state });

  // const contributingAuthors = [];

  // storyItems.forEach(s => {
  //   if (s.type === "story") {
  //     const story = s.story;

  //     story.authors.forEach(author => {
  //       if (contributingAuthors.find(ca => ca.id === author.id)) {
  //         return;
  //       }

  //       contributingAuthors.push(author);
  //     });
  //   }
  // });

  const integrationsConfig = IntegrationData();

  const isStaging = get(
    props.config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const enableAccesstype = get(
    props.config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const email = get(props.config, ["publisher", "publisher", "email"], "");
  const phone = get(props.config, ["publisher", "publisher", "phone"], "");

  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  const senaCampaigns = get(state, ["accessTypeCampaignSubscriptions"], []);

  // useEffect(() => {
  //   if (senaCampaigns.length) {
  //     setActiveSenaCampaigns(
  //       senaCampaigns.filter(
  //         (camp) => camp.campaign_active === true && camp.public === true
  //       )
  //     );
  //   }
  // }, [senaCampaigns]);
  // useEffect(() => {
  //   console.log({ state }, {coll} ,{storyItems2}, {firstCollectionStories}, {collection},{collections},{collectionOfCollection});
  // }, [state]);

  // useEffect(() => {
  //   if (activeSenaCampaigns.length) {
  //     setSortedCampaigns([
  //       ...activeSenaCampaigns.filter((as) => as.id !== 1500),
  //     ]);
  //   }
  // }, [activeSenaCampaigns]);

  return (
    <main>
      <section styleName="hero-section-desktop">
        {/* <div>
          <div styleName="hero-section-content">
            <h1 styleName="title">General Elections 2024 Fund</h1>
            <p styleName="border-bottom"></p>
            <p styleName="description">
              <i>Newslaundry</i> and <i>The News Minute</i> will cover the Lok
              Sabha polls together, with an ambitious reporting project to
              dissect the issues that matter and check the pulse on the ground.
            </p>
            <p styleName="description">
              Our reporters, editors and producers will bring you stories and
              shows from across the country. But only you can make this coverage
              possible.
            </p>
          </div>
          <div styleName="hero-section-video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/-oUlXJ9Wceo?si=vINGPhhmGii2hbIl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          {/* <div styleName="hero-section-img">
            <img
              src="https://images.assettype.com/newslaundry/2023-10/8bc203df-4daa-468b-9bb5-8cb5e0279550/Frame_22.png"
              // src="https://images.assettype.com/newslaundry/2024-02/55e78e65-006f-4bc6-bf51-2d9a6f1243c2/VERTICAL_BANNER_.jpg"
              alt="Nl-TNM logo banner"
            />
          </div> 
        </div> */}
        <img
          src={`https://images.assettype.com/newslaundry/2024-05/d29e814c-7141-4266-acf6-5666f2445788/ELECTION_BANNER___WEBSITE_.jpg${COMPRESS_PARAMS_EXTRA_SMALL}`}
          alt="Nl-TNM logo banner"
        />
      </section>
      <section styleName="hero-section-mobile">
        <img
          src={`https://images.assettype.com/newslaundry/2024-05/ecacdd5b-9cc4-4ab8-b1c6-a6fe5ec4a578/ELECTION_SQ_POST___1_.jpg${COMPRESS_PARAMS_EXTRA_SMALL}`}
          alt="Nl-TNM logo banner"
        />
      </section>
      <section styleName="sub-section">
        <p styleName="sub-section-description">
          A big thank to all of you who have contributed to the election fund so
          far! Scroll through to see our election shows and ground reports.
        </p>
        <p styleName="border-bottom"></p>

        {/* <div styleName="sub-section-card-wrapper">
          {sortedCampaigns.map((ac) => {
            return (
              <StandaloneSenaContainer
                key={ac.id}
                campaign={ac}
                member={state.member}
                navigateTo={props.navigateTo}
                currentPath={state.qt.currentPath}
                authHost={state.qt.config.publisher.publisher.auth_host}
              />
            );
          })}
        </div> */}
      </section>
      {/* <AccessType
        enableAccesstype={enableAccesstype}
        isAccessTypeCampaignEnabled={true}
        isStaging={false}
        accessTypeKey={accessTypeKey}
        accessTypeBkIntegrationId={accessTypeBkIntegrationId}
        email={email}
        phone={phone}
      >
        {() => {
          return (
            <section styleName="sub-section">
              <p styleName="sub-section-description">
              A big thank to all of you who have contributed to the election fund so far! Scroll through to see our election shows and ground reports.
              </p>
              <p styleName="border-bottom"></p>

              <div styleName="sub-section-card-wrapper">
                {sortedCampaigns.map(ac => {
                  return (
                    <StandaloneSenaContainer
                      key={ac.id}
                      campaign={ac}
                      member={state.member}
                      navigateTo={props.navigateTo}
                      currentPath={state.qt.currentPath}
                      authHost={state.qt.config.publisher.publisher.auth_host}
                    />
                  );
                })}
              </div>
            </section>
          );
        }}
      </AccessType>
      <section styleName="why-subscribe-wrapper">
        <h2>Here&apos;s why you should contribute</h2>
        <div styleName="why-subscribe-grid">
          <div styleName="why-subscribe-item">
            <div styleName="why-subscribe-icon-wrapper">
              <BsNewspaper styleName="why-subscribe-icon" />
            </div>
            <div styleName="why-subscribe-text-wrapper">
              <h3 styleName="why-subscribe--text-heading">
                Power ground reports
              </h3>
              <p styleName="why-subscribe--text-description">
                Reportage is how news consumers stay informed. Your
                contributions support reporters and power access to the facts on
                the ground.
              </p>
            </div>
          </div>
          <div styleName="why-subscribe-item">
            <div styleName="why-subscribe-icon-wrapper">
              <BiPhoneCall styleName="why-subscribe-icon" />
            </div>
            <div styleName="why-subscribe-text-wrapper">
              <h3 styleName="why-subscribe--text-heading">
                Interact with our editorial teams
              </h3>
              <p styleName="why-subscribe--text-description">
                Join Zoom calls with our reporters, producers and editors –
                before and after election coverage – for a closer look at our
                work.
              </p>
            </div>
          </div>
          <div styleName="why-subscribe-item">
            <div styleName="why-subscribe-icon-wrapper">
              <BsGiftFill styleName="why-subscribe-icon" />
            </div>
            <div styleName="why-subscribe-text-wrapper">
              <h3 styleName="why-subscribe--text-heading">Free merch</h3>
              <p styleName="why-subscribe--text-description">
                For all contribution levels higher than Soldier, get exclusive
                merchandise as a token of our appreciation, including our
                all-new <i>Stronger Together: NL-TNM</i> merch.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {CollectionData.length > 0 && (
        <div styleName="sec-collection" className="full-width-with-padding">
          {/* <h4> Election 2024 shows </h4> */}
          <div className="container" styleName="story-container">
            {CollectionData.map(collection => (
              <div styleName="card-container">
                {/* temp fix for tippani link in video section newslaundry */}
                <Link
                  href={collection.url}
                  styleName="hero-image"
                  className="hero-image"
                  //  aria-label={`Go to ${collectionName}`}
                >
                  <figure className="image">
                    <ResponsiveImage
                      slug={collection.imgUrl}
                      //  metadata={imgMetadata}
                      aspectRatio={[370, 208]}
                      defaultWidth={259}
                      widths={[250, 480]}
                      className="responsive-img"
                      alt="Article image"
                    />
                  </figure>
                </Link>
                <div styleName="text-container">
                  {/* temp fix for tippani link in video section newslaundry */}
                  <Link
                    href={collection.url}
                    //  aria-label={`${collectionName}`}
                  >
                    <h3 styleName="headline">
                      <bdi
                        dangerouslySetInnerHTML={{ __html: collection.title }}
                      />
                    </h3>
                  </Link>
                </div>
              </div>
            ))}
            {/* </ul> */}
          </div>
        </div>
      )}
      <section styleName="stories-collection-wrapper">
        <h2 styleName="stories-collection-heading">
          Your support made these stories happen
        </h2>

        <div styleName="stories-collection-grid">
          {stories.map(item => {
            const story = item.story;
            const heroS3Key = getHeroImage(story, config);
            const section = get(story, ["sections", "0"], {});

            return (
              <article key={story.id} styleName="story-collection-item">
                <figure className="figure">
                  <ResponsiveImage
                    slug={heroS3Key}
                    aspectRatio={[265, 354]}
                    defaultWidth={173}
                    widths={[173, 265]}
                    sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
                    imgParams={{ auto: ["format", "compress"] }}
                    className="responsive-img"
                    alt="Article image"
                  />
                </figure>
                <div styleName="story-text-wrapper">
                  <Link href={section["section-url"]}>
                    <p
                      styleName="section-badge"
                      aria-label={`Section: ${
                        section["display-name"] || section.name
                      } `}
                    >
                      {section["display-name"] || section.name}
                    </p>
                  </Link>
                  <Link href={story.url}>
                    <h3 styleName="story-headline" className="headline">
                      {story.headline}
                    </h3>
                  </Link>
                  <div styleName="story-author-timeago-wrapper">
                    <Authors story={story} />
                    <div>
                      <ReactTimeago
                        formatter={dateFormatterWithoutTime}
                        date={story["last-published-at"]}
                        live={false}
                        aria-hidden="true"
                        className="typography-font-sm"
                      />
                    </div>
                  </div>
                </div>
              </article>
            );
          })}
        </div>
        <Link href="/nl-tnm-2024-election-fund" styleName="redirect-link">
          View all stories
        </Link>
      </section>
      {/* <section styleName="contributing-authors-wrapper">
        <h2 styleName="stories-collection-heading">
          Our journalists on the ground
        </h2>

        <div styleName="contributing-authors-grid">
          {contributingAuthors.map(author => {
            return( <div key={author.id}>
              <Link href={`/author/`}>
              <img src={author["avatar-url"]} alt="" />
              <p >{author.name}</p>
              </Link>
            </div> )
          })}
        </div>
      </section> */}
    </main>
  );
};

const mapStateToProps = state => {
  return {
    hello: "world"
  };
};

const mapDispatchToProps = dispatch => ({
  navigateTo: url => global.app.navigateToPage(dispatch, url)
});

const ElectionFund2024 = connect(
  mapStateToProps,
  mapDispatchToProps
)(ElectionFund2024Base);

export { ElectionFund2024 };
