import React from "react";
import { useSelector } from "react-redux";
import assetify from "@quintype/framework/assetify";
import get from "lodash/get";
import LoadingIcon from "../../../../assets/images/loader.svg";
import "./podcast-letters.m.css";
import { SubscribePopup } from "../../molecules/popup/subscribe-popup";

export function PodcastLetters() {
  const hasSubscription = useSelector((state) => {
    return get(state, ["fetchSubscription", "isActive"], null);
  });
  const member = useSelector((state) => {
    return get(state, ["member"], null);
  });

  // console.log(hasSubscription);

  return (
    <React.Fragment>
      {!member ||
        (hasSubscription === null && (
          <div styleName="modal">
            <div styleName="modal-content">
              <img src={assetify(LoadingIcon)} alt="loading" />
            </div>
          </div>
        ))}

      <div
        style={!hasSubscription ? { opacity: "0.2" } : { opacity: "1" }}
        styleName="form"
      >
        <br />
        <iframe
          styleName="iframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLSc7GPSlA7dG7HVufK8YRUEF9nLXIA29eTujks7AidRSfCFh7w/viewform?embedded=true"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>

      {hasSubscription === false ||
        (!member && <SubscribePopup showModal={true} />)}
    </React.Fragment>
  );
}
