import React from "react";
import { Link } from "@quintype/components";

// import Events from "./images/events.jpeg";
// import Films from "./images/film.jpg";
// import Meetups from "./images/meetups.jpg";

import {
  COMPRESS_PARAMS_MEDIUM,
  SUBSCRIBER_ONLY_EVENTS,
  SUBSCRIBER_ONLY_FILM_SCREENINGS,
  SUBSCRIBER_ONLY_MEET_UPS
} from "../../../../image-constants";

import "./subscription-photo-gallery.m.css";
// import assetify from "@quintype/framework/assetify";

const SubscriptionPhotoCollage = () => {
  return (
    <div className="full-width-with-padding" styleName="photo-gallery-wrapper">
      <section styleName="photo-gallery-section">
        <div styleName="photo-gallery-heading-wrapper">
          <h2 styleName="photo-gallery-heading">Our subscriber-only events</h2>
          <div styleName="photo-gallery-border-bottom"></div>
        </div>
        <p styleName="photo-gallery-description">
          We love hanging out with our subscribers! See photos from film
          screenings, events and meet-ups. Subscribe now to get full access to
          the gallery.
        </p>
        <div styleName="photo-gallery-grid">
          <div styleName="photo-gallery-grid-item">
            <img
              styleName="photo-gallery-grid-item-image"
              src={`${SUBSCRIBER_ONLY_MEET_UPS}${COMPRESS_PARAMS_MEDIUM}`}
              alt="subscribers meetup"
            />
            <p styleName="photo-gallery-grid-item-caption">
              Subscriber meet-ups
            </p>
          </div>
          <div styleName="photo-gallery-grid-item">
            <img
              styleName="photo-gallery-grid-item-image"
              src={`${SUBSCRIBER_ONLY_FILM_SCREENINGS}${COMPRESS_PARAMS_MEDIUM}`}
              alt="film screenings"
            />
            <p styleName="photo-gallery-grid-item-caption">Film screenings</p>
          </div>
          <div styleName="photo-gallery-grid-item">
            <img
              styleName="photo-gallery-grid-item-image"
              src={`${SUBSCRIBER_ONLY_EVENTS}${COMPRESS_PARAMS_MEDIUM}`}
              alt="events"
            />
            <p styleName="photo-gallery-grid-item-caption">Events</p>
          </div>
          {/* <div styleName="photo-gallery-grid-item"></div>
          <div styleName="photo-gallery-grid-item"></div>
          <div styleName="photo-gallery-grid-item"></div>
          <div styleName="photo-gallery-grid-item"></div>
          <div styleName="photo-gallery-grid-item"></div>
          <div styleName="photo-gallery-grid-item"></div> */}
        </div>
        {/* <div style={{
          width: "100%",
          display: "grid",

        }}>
          <div></div>
        </div> */}
        <Link href="/photo-gallery" styleName="redirect-link">
          View all
        </Link>
      </section>
    </div>
  );
};

export { SubscriptionPhotoCollage };
