import { EagerLoadImages, wrapCollectionLayout } from "@quintype/components";
import React from "react";
import OneRowThreeCol from "../collection-templates/one-row-three-col";
import TwocollectionsWithSevenStories from "../collection-templates/twocollections-with-seven-stories";
import { ComicsRow } from "./comics";
import { DownloadAppBanner } from "./download-app-banner";
import { FourStoriesComponent } from "./four-stories-component";
import { FourStoriesWithBanner } from "./four-stories-with-banner";
import { FourStoriesWithCampaign } from "./four-stories-with-campaign";
import { LiveVideo } from "./live-video";
import { NewsletterComponent } from "./newsletter";
import { NlPodcastRow } from "./nl-podcast";
import { NlHindiPodcastRow } from "./nl-podcast-hindi";
import { SevenStoriesWithCampaign } from "./seven-stories-with-campaign";
import { StopPressNewsletter } from "./stoppress-newsletter";
import { SubscribeNowTemplate } from "./subscribe-now";
import { SubscribeToNewsletters } from "./subscribe-to-newsletters";
import { ThreeColThreeCollectionStories } from "./three-col-three-collection";
import ThreeCollectionsWithPoll from "./three-collection-with-poll";
import { ThreeHindiColumns } from "./three-hindi-columns";
import { ThreeSenaCollectionHybrid } from "./three-sena-collection-hybrid";
import { TippaniComponent } from "./tippani-component";
import { TwoRowsFiveStoriesVertical } from "./two-rows-five-stories-vertical";
import { TwoRowsFourStories } from "./two-rows-four-stories";
import { UdayFoundationBanner } from "./uday-foundation";
import { VideoPlaylist } from "./video-playlist";
import { HindiPaywallBanner } from "./hindi-paywall-banner";
import { FourStoriesWithHindiCampaign } from "./four-stories-with-hindi-campaign";

// This should not be needed anymore as we are using Gumlet
function wrapEager(f) {
  const wrappedComponent = function WrapEager(props) {
    if (props.index === 0) {
      return (
        <EagerLoadImages predicate={token => token === "above-fold"}>
          {React.createElement(f, props)}
        </EagerLoadImages>
      );
    } else {
      return React.createElement(f, props);
    }
  };

  if (f.storyLimit) {
    wrappedComponent.storyLimit = f.storyLimit;
  }

  return wrappedComponent;
}

export default {
  FourStoriesComponent: wrapEager(wrapCollectionLayout(FourStoriesComponent)),
  // SubscribeToNewsletters: wrapEager(wrapCollectionLayout(SubscribeToNewsletters)),
  // TwocollectionsWithSevenStories: wrapEager(
  //   wrapCollectionLayout(TwocollectionsWithSevenStories)
  // ),
  // TwoRowsFourStories: wrapEager(wrapCollectionLayout(TwoRowsFourStories)),
  // SubscribeNowTemplate: wrapEager(wrapCollectionLayout(SubscribeNowTemplate)),
  // OneRowThreeCol: wrapEager(wrapCollectionLayout(OneRowThreeCol)),
  // ThreeColThreeCollectionStories: wrapEager(
  //   wrapCollectionLayout(ThreeColThreeCollectionStories)
  // ),
  // ThreeCollectionsWithPoll: wrapEager(
  //   wrapCollectionLayout(ThreeCollectionsWithPoll)
  // ),
  // NlPodcastRow: wrapEager(wrapCollectionLayout(NlPodcastRow)),
  // NlHindiPodcastRow: wrapEager(wrapCollectionLayout(NlHindiPodcastRow)),
  // ThreeHindiColumns: wrapEager(wrapCollectionLayout(ThreeHindiColumns)),
  // TippaniComponent: wrapEager(wrapCollectionLayout(TippaniComponent)),
  // VideoPlaylist: wrapEager(wrapCollectionLayout(VideoPlaylist)),
  // ComicsRow: wrapEager(wrapCollectionLayout(ComicsRow)),
  // FourStoriesWithCampaign: wrapEager(
  //   wrapCollectionLayout(FourStoriesWithCampaign)
  // ),
  // ThreeSenaCollectionHybrid: wrapEager(
  //   wrapCollectionLayout(ThreeSenaCollectionHybrid)
  // ),
  // SevenStoriesWithCampaign: wrapEager(
  //   wrapCollectionLayout(SevenStoriesWithCampaign)
  // ),
  // FourStoriesWithBanner: wrapEager(wrapCollectionLayout(FourStoriesWithBanner)),
  // UdayFoundationBanner: wrapEager(wrapCollectionLayout(UdayFoundationBanner)),
  // StopPressNewsletter: wrapEager(wrapCollectionLayout(StopPressNewsletter)),
  // defaultTemplate: wrapCollectionLayout(OneRowThreeCol),
  // TwoRowsFiveStoriesVertical: wrapEager(
  //   wrapCollectionLayout(TwoRowsFiveStoriesVertical)
  // ),
  // LiveVideo: wrapEager(wrapCollectionLayout(LiveVideo)),
  // DownloadAppBanner: wrapEager(wrapCollectionLayout(DownloadAppBanner)),
  // NewsletterComponent: wrapEager(wrapCollectionLayout(NewsletterComponent)),
  // HindiPaywallBanner: wrapEager(wrapCollectionLayout(HindiPaywallBanner)),
  // FourStoriesWithHindiCampaign: wrapEager(
  //   wrapCollectionLayout(FourStoriesWithHindiCampaign)
  // )

  // NEW
  SubscribeToNewsletters: wrapCollectionLayout(SubscribeToNewsletters),
  TwocollectionsWithSevenStories: wrapCollectionLayout(
    TwocollectionsWithSevenStories
  ),
  TwoRowsFourStories: wrapCollectionLayout(TwoRowsFourStories),
  SubscribeNowTemplate: wrapCollectionLayout(SubscribeNowTemplate),
  OneRowThreeCol: wrapCollectionLayout(OneRowThreeCol),
  ThreeColThreeCollectionStories: wrapCollectionLayout(
    ThreeColThreeCollectionStories
  ),
  ThreeCollectionsWithPoll: wrapCollectionLayout(ThreeCollectionsWithPoll),
  NlPodcastRow: wrapCollectionLayout(NlPodcastRow),
  NlHindiPodcastRow: wrapCollectionLayout(NlHindiPodcastRow),
  ThreeHindiColumns: wrapCollectionLayout(ThreeHindiColumns),
  TippaniComponent: wrapCollectionLayout(TippaniComponent),
  VideoPlaylist: wrapCollectionLayout(VideoPlaylist),
  ComicsRow: wrapCollectionLayout(ComicsRow),
  FourStoriesWithCampaign: wrapCollectionLayout(FourStoriesWithCampaign),
  ThreeSenaCollectionHybrid: wrapCollectionLayout(ThreeSenaCollectionHybrid),
  SevenStoriesWithCampaign: wrapCollectionLayout(SevenStoriesWithCampaign),
  FourStoriesWithBanner: wrapCollectionLayout(FourStoriesWithBanner),
  UdayFoundationBanner: wrapCollectionLayout(UdayFoundationBanner),
  StopPressNewsletter: wrapCollectionLayout(StopPressNewsletter),
  defaultTemplate: wrapCollectionLayout(OneRowThreeCol),
  TwoRowsFiveStoriesVertical: wrapCollectionLayout(TwoRowsFiveStoriesVertical),
  LiveVideo: wrapCollectionLayout(LiveVideo),
  DownloadAppBanner: wrapCollectionLayout(DownloadAppBanner),
  NewsletterComponent: wrapCollectionLayout(NewsletterComponent),
  HindiPaywallBanner: wrapCollectionLayout(HindiPaywallBanner),
  FourStoriesWithHindiCampaign: wrapCollectionLayout(
    FourStoriesWithHindiCampaign
  )
};
