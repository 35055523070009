import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import nlSenaLogo from "../../../../assets/images/nl-images/NLSenaPage-image-on-topleft.png";
import { NLFundProjectCard } from "./nl-fund-project-card";
import CommonStoryCard from "../../molecules/common-story-card";
import getStoriesByCollection from "../../../utils";
import Loader from "../../atoms/loader";
import { cases } from "./cases";

import "./nl-legal-fund-page.m.css";
import { NLLegalFundLevels } from "./nl-legal-fund-levels";
import LegalCaseComponent from "./cases-component";
import {
  BIRUBAL_BIRUBALA_LAWYERS_LEGAL_FUND,
  COMPRESS_PARAMS_MEDIUM
} from "../../../image-constants";

const RowWithHeadline = ({ title, showBottom, campSubscriptions }) => {
  return (
    <>
      <div styleName="heading">
        <div>{title}</div>
        <div styleName="line" />
      </div>
      <div styleName="row">
        {campSubscriptions &&
          campSubscriptions.reverse().map((subscriptions, index) => {
            if (subscriptions.name === "Test Legal Campaign") {
              return;
            }

            return (
              <NLFundProjectCard
                showBottom={showBottom}
                subscriptions={subscriptions}
                key={index}
                redirectUrl="/sena"
              />
            );
          })}
      </div>
    </>
  );
};

RowWithHeadline.propTypes = {
  title: PT.string,
  showBottom: PT.bool,
  campSubscriptions: PT.array
};

class NLLegalFundComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: {}
    };
  }

  componentDidMount() {
    this.props.initAccessType();
  }

  render() {
    const img = get(this.props, [
      "data",
      "collection",
      "metadata",
      "cover-image",
      "cover-image-url"
    ]);
    const campSubscriptions = get(this.props, ["campaignSubscriptions"], []);

    const currentProjects = campSubscriptions.filter(
      subscriptions =>
        subscriptions.name !== "Test Legal Campaign" &&
        subscriptions.target_reached === false
    );

    const completedProjects = campSubscriptions.filter(
      subscriptions => subscriptions.target_reached === true
    );
    const levels = get(
      this.props,
      ["config", "nlLegalFundLevels", "levels"],
      []
    );

    const collection = get(this.props, ["data", "collection"], {});
    const stories = getStoriesByCollection(collection) || [];

    return (
      <section>
        <main styleName="hero-section">
          <div>
            <div styleName="hero-section-content">
              <div styleName="title-wrapper">
                <h1 styleName="title">NL Legal Fund</h1>
                <p styleName="border-bottom"></p>
              </div>
              <p styleName="description">
                Reporting the truth and standing up to power comes at a cost. We
                adhere to the highest standards of free, fair and honest
                journalism. Police cases, FIRs, raids and lawsuits are
                byproducts. We are not intimidated by such actions.
                <br />
                <br />
                While the law protects us, it can often be a long-drawn process
                in court with a significant cost. Much time and money is spent.
                What keeps us steadfast is also what keeps us independent and
                unafraid. <span styleName="text-red">You!</span> We don’t depend
                on advertisers – governments or corporations. We depend on our
                subscribers. Our transparency is our strength, as are you.{" "}
                <span styleName="text-red">
                  Help us fund our legal battles.
                </span>
              </p>
            </div>
            <div styleName="hero-section-img-div">
              <div styleName="hero-section-img">
                <img
                  src={`${BIRUBAL_BIRUBALA_LAWYERS_LEGAL_FUND}${COMPRESS_PARAMS_MEDIUM}`}
                  alt="legal fund hero image"
                />
              </div>
              <div styleName="embed-div">
                <iframe
                  src="https://flo.uri.sh/visualisation/10101032/embed"
                  title="Interactive or visual content"
                  className="flourish-embed-iframe"
                  frameBorder="0"
                  scrolling="no"
                  style={{ width: "100%", height: "200px" }}
                  sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
                ></iframe>
              </div>
            </div>
          </div>
        </main>
        {campSubscriptions.length > 0 ? (
          <div styleName="proj-container" className="full-width">
            <div className="container" styleName="proj-wrapper">
              {currentProjects.length > 0 && (
                <RowWithHeadline
                  title="Active Cases"
                  campSubscriptions={currentProjects}
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "60px 0" }}>
            <Loader color="#ec2227" />
          </div>
        )}
        <section styleName="legal-cases-section">
          <div styleName="heading">
            <div>FIRs and ongoing cases</div>
            <div styleName="line" />
          </div>
          <div styleName="legal-cases-items">
            {cases.map((item, i) => (
              <LegalCaseComponent key={i} item={item} />
            ))}
          </div>
        </section>
        {campSubscriptions.length > 0 ? (
          <div styleName="proj-container" className="full-width">
            <div className="container" styleName="proj-wrapper">
              {completedProjects.length > 0 && (
                <RowWithHeadline
                  title="Funded Projects"
                  showBottom={false}
                  campSubscriptions={completedProjects}
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "60px 0" }}>
            <Loader color="#ec2227" />
          </div>
        )}
        <h2 styleName="story-heading">Gifts and Benefits</h2>

        <div styleName="gift-conatiner">
          {levels.length > 0 && <NLLegalFundLevels levels={levels} />}
        </div>
        {stories.length > 0 && (
          <div styleName="proj-container" className="full-width">
            <div className="container" styleName="proj-wrapper">
              <h2 styleName="story-heading">Read more</h2>
              <div styleName="subscription-collection">
                {stories.map((story, index) => {
                  return (
                    <CommonStoryCard
                      story={story}
                      key={index}
                      showSection={true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

NLLegalFundComponent.propTypes = {
  initAccessType: PT.func,
  config: PT.object
};

export { NLLegalFundComponent };
