import React, { useEffect, useState } from "react";
import { object, shape, func, array } from "prop-types";
import get from "lodash/get";
import {
  LoadMoreCollectionStories,
  LazyLoadImages,
  LazyCollection
} from "@quintype/components";
import ThreeStoriesWithSubscribeMail from "../../rows/three-stories-with-subscribe-mail";
import { getCollectionTemplate } from "../../get-collection-template";
import HorizontalStoryCardList from "../../molecules/horizontal-story-card-list";
import VerticleStoryCardList from "../../molecules/verticle-story-card-list";
import getStoriesByCollection from "../../../utils";
import { LoadMoreButton } from "../../atoms/load-more-button";

import "./general-section.m.css";

const GeneralSectionPage = props => {
  const [location, setLocation] = useState("");
  useEffect(() => {
    setLocation(global.location.host);
  }, []);

  // don't know why this was done
  const validLocations = [
    "www.newslaundry.com",
    "newslaundry-web.qtstage.io",
    "newslaundry-beta.quintype.io",
    "localhost:3000"
  ];

  // console.log(props.data.collection);

  const stories =
    validLocations.includes(location) &&
    props.data.collection.slug !== "nl-tnm-2024-election-fund"
      ? getStoriesByCollection(props.data.collection)
          .filter(item => item.url.slice(8, 13) !== "hindi")
          .slice(0, 19) || []
      : getStoriesByCollection(props.data.collection)
          .sort(
            (a, b) => new Date(b["published-at"]) - new Date(a["published-at"])
          )
          .slice(0, 19) || [];

  // console.log({ location });
  // console.log({ stories });

  const totalCount = get(
    props,
    ["data", "collection", "collection", "total-count"],
    ""
  );
  const isCollectionPresent = get(
    props,
    ["data", "collection", "items"],
    []
  ).filter(collection => collection.type === "collection");
  const limit = isCollectionPresent && isCollectionPresent[0] ? "3" : "4";

  if (stories.length < 1 && isCollectionPresent.length < 1) {
    return <div styleName="not-found">Stories not found...</div>;
  }

  const storiesTemplate = ({ onLoadMore, stories }) => {
    return (
      <div styleName="story-wrapper">
        <div className="desktop-only">
          <HorizontalStoryCardList
            stories={stories.slice(limit - 1, stories.length)}
          />
        </div>
        <div className="mobile-only">
          <VerticleStoryCardList
            stories={stories.slice(limit - 1, stories.length)}
          />
        </div>
        <div styleName="load-more-btn-wrapper">
          {totalCount !== stories.length && (
            <LoadMoreButton onClick={onLoadMore} />
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {stories.length > 0 && (
        <ThreeStoriesWithSubscribeMail
          limit={limit}
          {...props}
          stories={stories}
        />
      )}
      {isCollectionPresent.length > 0 && (
        <div styleName="remove-infinite-data-margin" role="list">
          <LazyLoadImages>
            <LazyCollection
              collection={props.data.collection}
              collectionTemplates={getCollectionTemplate}
              lazyAfter={2}
            />
          </LazyLoadImages>
        </div>
      )}
      <div styleName="bottom-fold">
        <LoadMoreCollectionStories
          template={storiesTemplate}
          data={{ stories: stories }}
          collectionSlug={props.data.collection.slug}
          numStoriesToLoad={10}
          params={{ "item-type": "story" }}
        />
      </div>
    </React.Fragment>
  );
};

GeneralSectionPage.propTypes = {
  data: shape({
    collection: object
  }),
  onLoadMore: func,
  stories: array
};

export default GeneralSectionPage;
