import React, { useState, useEffect } from "react";
import SearchArea from "../../components/voteSteps/votesearch";
import VoteStepa from "../../components/voteSteps/voteStep2";
// import JournalismSpectrumEdit from "../../components/Grid/SpectrumEditPage";
import Axios from "axios";
import { parseUrl } from "query-string";
import "../../index.m.css";
import { FaCheckCircle, FaRegFrownOpen } from "react-icons/fa";
import { NotFoundPage } from "../../../not-found/index.js";
import { SubscribePopup } from "../../../../molecules/popup/subscribe-popup";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";
import {
  saveDataToLocalStorage,
  fetchDataFromLocalStorage
} from "../../../subscription-checkout/utils";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "@quintype/components";
const btnFix = {
  position: "relative"
};

const btnBack = {
  background: "none",
  border: "none",
  color: "#214354",
  fontWeight: "900",
  fontSize: "34px",
  margin: " 0 1% 0 0",
  padding: "5px",
  cursor: "pointer"
};

const Voting = () => {
  // const [swingState, setSwingState] = useState("");
  const [selectog, setSelectTog] = useState([]);
  const [warntog, setWarntog] = useState(false);
  const [steps, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitErr, setSubmitErr] = useState(false);
  const { qt } = useSelector(state => state);
  const authHost = qt.config.publisher.publisher.auth_host;
  const member = useSelector(state => get(state, ["member"], null));
  const shareUrl = "https://newslaundry.com/biascope";
  const title =
    "How biased are India's favourite journalists? I voted, and you should too.\nCheck it out here:";
  const fetchSubscription = useSelector(state =>
    get(state, ["fetchSubscription"], null)
  );
  const isActive = fetchSubscription && fetchSubscription.isActive;

  const handleClick = () => {
    setIsOpen(!isOpen);
    setStep(ini => ini + 0);
  };
  const handleBtnClick = async () => {
    const params = parseUrl(qt.currentPath);
    saveDataToLocalStorage("voting", "step");
    saveDataToLocalStorage(JSON.stringify(selectog), "selectog");
    const callbackUrl = get(
      params,
      ["query", "callback-url"],
      global.location && global.location.origin
    );
    const redirectUrl = get(
      params,
      ["query", "redirect-url"],
      global && global.location && global.location.href
    );
    window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  };

  const [journalists, setJournalists] = useState([]);

  useEffect(() => {
    Axios.get(
      "https://server.newslaundry.com/journalismSpectrum/journalists"
    ).then(response => {
      setJournalists(response.data.journalists);
    });
  }, []);

  useEffect(() => {
    setSelectTog(JSON.parse(fetchDataFromLocalStorage("selectog")) || []);
    if (member && fetchSubscription.items.length) {
      setModalOpen(false);
      if (fetchDataFromLocalStorage("step") == "voting") {
        setStep(2);
      }
    } else {
      if (fetchDataFromLocalStorage("step") == "voting") {
        if (member && !fetchSubscription.items.length) {
          setModalOpen(true);
        }
        // window.localStorage.removeItem("step");
      }
    }
  }, [fetchSubscription, member]);

  const warn = {
    color: "#ed1b24",
    background: "rgb(255 202 202)",
    padding: "20px",
    borderRadius: "5px",
    display: warntog ? "block" : "none",
    textAlign: "center"
  };

  switch (steps) {
    case 1:
      return (
        <>
          <>
            <div styleName="container">
              {modalOpen && (
                <SubscribePopup
                  showModal={modalOpen}
                  setShowModal={setModalOpen}
                />
              )}
              <h4 style={warn}>Please Choose One!</h4>
              <SearchArea
                allres={journalists}
                selected={selectog}
                onClick={val => setSelectTog([...selectog, val])}
                onDelete={val => {
                  let newArr = selectog.filter(v => {
                    return v != val;
                  });
                  // console.log({newArr});
                  setSelectTog(newArr);
                }}
              />
              {isActive ? (
                <div styleName="ctav-floating-container">
                  <button
                    onClick={() => {
                      selectog.length == 0
                        ? setWarntog(true)
                        : setStep(ini => ini + 1);
                      window.scrollTo(0, 0);
                    }}
                    styleName="ctav"
                    style={btnFix}
                  >
                    Proceed to vote <span> ➜</span>
                  </button>
                </div>
              ) : member ? (
                <div styleName="ctav-floating-container">
                  <button
                    onClick={() => {
                      selectog.length == 0
                        ? setWarntog(true)
                        : setModalOpen(true);
                    }}
                    styleName="ctav"
                    style={btnFix}
                  >
                    Proceed to vote <span> ➜</span>
                  </button>
                </div>
              ) : (
                <>
                  <div styleName="ctav-floating-container">
                    <button
                      onClick={() => {
                        selectog.length == 0
                          ? setWarntog(true)
                          : handleBtnClick();
                      }}
                      styleName="ctav"
                      style={btnFix}
                    >
                      Proceed to vote <span> ➜</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        </>
      );
    case 2:
      return (
        <>
          {!isActive ? (
            <NotFoundPage />
          ) : (
            <div styleName="container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  padding: "0 10px"
                }}
              >
                <button
                  onClick={() => {
                    setStep(ini => ini - 1);
                    window.scrollTo(0, 0);
                  }}
                  style={btnBack}
                >
                  ⬅
                </button>
                <br />
                <h1 style={{ padding: "auto", width: "95%" }}>
                  Cast your vote
                </h1>
              </div>
              <VoteStepa
                allres={journalists}
                selected={selectog}
                onClick={val => {
                  setStep(ini => ini + val);

                  window.scrollTo(0, 0);
                }}
                setSubmitErr={setSubmitErr}
              />
              <br />
            </div>
          )}
        </>
      );
    case 3:
      return (
        <>
          {!isActive ? (
            <NotFoundPage />
          ) : (
            <>
              {!submitErr && (
                <div styleName="container" style={{ padding: "0 1rem" }}>
                  <div styleName="success">
                    <h3>
                      <FaCheckCircle
                        style={{ color: "green", padding: "5px 0 0 0" }}
                      />{" "}
                      Vote successfull!
                    </h3>
                    <p style={{ padding: "20px 0" }}>
                      Thanks for voting! Share the Biascope with friends so they
                      can vote too.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        gap: "20px"
                      }}
                    >
                      <button
                        style={{
                          borderRadius: "10px",
                          padding: "10px 15px",
                          cursor: "pointer"
                        }}
                        onClick={handleClick}
                      >
                        <div style={{ paddingTop: "3px" }}>
                          <TwitterShareButton url={shareUrl} title={title}>
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>{" "}
                          <WhatsappShareButton url={shareUrl} title={title}>
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>{" "}
                          <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>{" "}
                          <LinkedinShareButton url={shareUrl} title={title}>
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>{" "}
                        </div>
                        {/* )} */}
                      </button>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "10px"
                        }}
                      >
                        <button
                          styleName="ctav"
                          onClick={() => {
                            setSelectTog([]);
                            setWarntog(false);
                            setStep(1);
                          }}
                        >
                          ⬅ Vote for another journalist
                        </button>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                          }}
                        >
                          <Link styleName="ctav" href={"/biascope-results"}>
                            Results
                          </Link>
                          <Link styleName="ctav" href={"/biascope"}>
                            Return to Home
                          </Link>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              )}
              {submitErr && (
                <div styleName="container" style={{ padding: "0 1rem" }}>
                  <div styleName="voteErr">
                    <h3>
                      <FaRegFrownOpen
                        style={{ color: "red", padding: "5px 0 0 0" }}
                      />{" "}
                      Server Error. Please try again.
                    </h3>
                    <br />
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px"
                        }}
                      >
                        <button
                          styleName="ctav"
                          onClick={() => {
                            setSelectTog([]);
                            setWarntog(false);
                            setStep(1);
                          }}
                        >
                          ⬅ Try again
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div
                styleName="credits"
                style={{ backgroundColor: "#ffeeee", borderRadius: "10px" }}
              >
                <p>
                  <b>Team</b>
                  <br />
                  <span style={{ fontWeight: "bolder" }}>
                    Back-end and front-end
                  </span>
                  <br /> Rishabh Dixit, Aditya Vikram, Yugal Verma, Sneha
                  Narayanan, Krishna Chaitanya Acondy*
                  <br />
                  <span style={{ fontWeight: "bolder" }}>UX</span>
                  <br /> Himanshu Jangir* <br />
                  <span style={{ fontWeight: "bolder" }}>Logo and artwork</span>
                  <br /> Shambhavi Trivedi, Gobindh VB <br />
                  <span style={{ fontWeight: "bolder" }}>
                    Concept and copywriting
                  </span>
                  <br /> Abhinandan Sekhri, Jayashree Arunachalam and Chitranshu
                  Tewari
                  <br />
                  <br />
                  <i>
                    *Our amazing subscribers who stepped up and pitched in to
                    put this together
                  </i>
                </p>
              </div>

              <br />
            </>
          )}
        </>
      );
    default:
      break;
  }
};

export { Voting };
