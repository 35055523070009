import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import getStoriesByCollection, { getSlug } from "../../../utils";
import "./podcast.m.css";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import Arrow from "../../../../assets/images/arrow-right-black.svg";
import { connect } from "react-redux";
// import { SectionName } from "../../atoms/section-tag";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { CardImage } from "../../atoms/card-image";
import { CollectionName } from "../../atoms/collection-name";
import { Link, ResponsiveImage } from "@quintype/components";
import Carousel from "../../collection-templates/comics/carousel";
import CommonStoryCard from "../../molecules/common-story-card";
import {
  BIRUBAL_READING_NEWS_PODCAST_INVERTED,
  COMPRESS_PARAMS_MEDIUM
} from "../../../image-constants";

const PodcastStory = ({ story }) => {
  const getLabel = get(
    story,
    ["metadata", "story-attributes", "storyattribute", 0],
    ""
  );
  const sections = get(story, ["sections", "0"], {});

  return (
    <div styleName="story-wrapper">
      {getLabel && <div styleName="ribbon">{getLabel}</div>}
      <div styleName="story-image">
        <CardImage story={story} />
      </div>
      <div styleName="story-content">
        <div styleName="sections">
          <Link
            href={sections["section-url"]}
            aria-label={`Click to go to section:${
              sections["display-name"] || sections.name
            }`}
          >
            <p styleName="section-badge">
              {sections["display-name"] || sections.name}
            </p>
          </Link>
          {/* <SectionName story={story} /> */}
        </div>
        <div styleName="headline">
          <Headline story={story} externalLink={true} />
        </div>
        <Subheadline story={story} />
      </div>
    </div>
  );
};

PodcastStory.propTypes = {
  story: PropTypes.object
};

const PodcastPageBase = ({ data, config }) => {
  const podcastDescription = get(data, ["collection", "summary"], "");
  const collection = data.collection || {};
  const podcastStory = getStoriesByCollection(collection);
  const collections =
    collection.items.filter(collection => collection.type === "collection") ||
    [];
  const defaultImgS3Key = get(
    config,
    ["publisher", "publisher", "defaulImgS3key"],
    ""
  );

  const children = podcastStory.slice(1).map(item => (
    <div styleName="card-wrapper" key={item}>
      <CommonStoryCard story={item} showAuthorWithTime={false} />
    </div>
  ));
  const firstCollection = collections && collections[0];
  const collectionOfCollection =
    (firstCollection &&
      firstCollection.items &&
      firstCollection.items.filter(
        collection => collection.type === "collection"
      )) ||
    [];

  const [location, setLocation] = useState("");

  useEffect(() => {
    setLocation(global.location.host);
  }, []);

  return (
    <>
      <div className="full-width-with-padding" styleName="wrapper">
        <div className="full-width-with-padding" styleName="inner-wrapper">
          <div styleName="main-grid">
            <div>
              {(location === "hindi.newslaundry.com" ||
                location === "newslaundry-hindi-web.qtstage.io" ||
                location === "hindi.lvh.me:3000") && (
                <h1 styleName="section-name">एनएल पॉडकास्ट</h1>
              )}
              {(location === "www.newslaundry.com" ||
                location === "newslaundry-web.qtstage.io" ||
                location === "newslaundry-beta.quintype.io" ||
                location === "localhost:3000") && (
                <h1 styleName="section-name">NL Podcast</h1>
              )}

              <div styleName="border-bottom"></div>
              {podcastDescription && (
                <p styleName="description">{podcastDescription}</p>
              )}
            </div>
            {podcastDescription && (
              <div styleName="image">
                <img
                  styleName="podcast-image"
                  src={`${BIRUBAL_READING_NEWS_PODCAST_INVERTED}${COMPRESS_PARAMS_MEDIUM}`}
                  alt="nl-podcast"
                ></img>
              </div>
            )}
          </div>
        </div>
      </div>
      <div styleName="collection-stories" className="full-width-with-padding">
        <div styleName="main-wrapper">
          {podcastStory.slice(0, 1).map((story, index) => {
            return <PodcastStory story={story} key={index} />;
          })}
          <br />
          <br />
          <div styleName="carousel">
            <Carousel
              carouselName={`carousel-${collection.slug}`}
              options={{
                type: `${podcastStory.length <= 4 ? "slider" : "carousel"}`,
                perView: 3,
                gap: 30,
                breakpoints: {
                  580: { perView: 1, gap: 32, peek: { before: 0, after: 80 } },
                  992: { perView: 3, peek: { before: 0, after: 80 } }
                }
              }}
              totalItems={podcastStory.length}
              showArrows={true}
              renderCenterLeftControls={({ previousSlide, currentSlide }) => {
                return (
                  currentSlide !== 0 && (
                    <button onClick={previousSlide} className="slider-btn">
                      <img
                        src={`${assetify(Arrow)}`}
                        alt="left"
                        styleName="slider-arrow arrow-left"
                      />
                    </button>
                  )
                );
              }}
              renderCenterRightControls={({ nextSlide }) =>
                podcastStory.length > 4 && (
                  <button onClick={nextSlide} className="slider-btn">
                    <img
                      src={`${assetify(Arrow)}`}
                      alt="right"
                      styleName="slider-arrow"
                    />
                  </button>
                )
              }
            >
              {children}
            </Carousel>
          </div>
          {collectionOfCollection.length > 0 && (
            <div styleName="collection-wrapper">
              <CollectionName collection={firstCollection} />
              <div styleName="collections">
                {collectionOfCollection.map((collection, index) => {
                  const coverImgUrl = get(
                    collection,
                    ["metadata", "cover-image", "cover-image-s3-key"],
                    defaultImgS3Key
                  );
                  let slug = getSlug(collection, config);
                  const coverImgMetadata = get(
                    collection,
                    ["metadata", "cover-image", "cover-image-metadata"],
                    {}
                  );

                  // temp fix for lets talk about page url
                  if (
                    slug ===
                    "https://www.newslaundry.com/podcast/lets-talk-about"
                  )
                    slug = "/collection/lets-talk-about-episodes";
                  return (
                    <Link
                      href={`${slug}`}
                      // href={`/collection/${collection.slug}`}
                      styleName="link-wrapper"
                      key={index}
                    >
                      <div key={index} styleName="stories">
                        <div key={index} styleName="hero-image">
                          <figure className="image">
                            <ResponsiveImage
                              slug={coverImgUrl}
                              metadata={coverImgMetadata}
                              aspectRatio={[16, 9]}
                              styleName="image"
                              defaultWidth={259}
                              widths={[250, 375]}
                              alt={"related show image"}
                              className="responsive-img"
                            />
                          </figure>
                        </div>
                        <div styleName="content">
                          <h2 styleName="name">{collection.name}</h2>
                          <div styleName="summary">{collection.summary}</div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

PodcastPageBase.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {})
});

export const PodcastPage = connect(mapStateToProps)(PodcastPageBase);
