import React from "react";
// import assetify from "@quintype/framework/assetify";
import { FEATURES } from "../constants";
import { COMPRESS_PARAMS_SMALL } from "../../../../image-constants";

import "./features.m.css";

const IS_HINDI = !!(global.location && global.location.host.includes("hindi"));

export const Features = () => {
  return (
    <div styleName="wrapper" className="full-width">
      {!IS_HINDI ? (
        <h2 styleName="section-title">Why should you subscribe?</h2>
      ) : (
        <h2 styleName="section-title">आपको सबस्क्रिप्शन क्यों लेना चाहिए?</h2>
      )}
      <div styleName="features-wrapper">
        {FEATURES.map((item, index) => {
          return (
            <div
              styleName={`feature ${index % 2 === 0 ? "even" : "odd"}`}
              key={index}
            >
              <img
                src={`${item.imgSrc}${COMPRESS_PARAMS_SMALL}`}
                alt={item.title}
              />
              <div styleName="text-wrapper">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              {/* {index % 2 === 0 ? (
                <>
                  <img src={assetify(item.imgSrc)} alt={item.title} />
                  <div styleName="text-wrapper">
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div styleName="text-wrapper">
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                  <img src={assetify(item.imgSrc)} alt={item.title} />
                </>
              )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};
