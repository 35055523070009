import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import HorizontalStoryCard from "../../molecules/horizontal-story-card";
import CommonStoryCard from "../../molecules/common-story-card";
import { LoadMoreStoriesBase } from "@quintype/components";
import getStoriesByCollection from "../../../utils";
import Trending from "../../molecules/trending";
import { LoadMoreButton } from "../../atoms/load-more-button";
import { storyFields } from "../../../constants";
import "./tag.m.css";

export const TagPage = ({ data = {} }) => {
  const tagName = get(data, ["tag", "name"], "");
  const trendingStories = getStoriesByCollection(
    get(data, ["trendingCollection", "collection"], {})
  );
  const collection = {
    name: "Trending"
  };

  const TagPageUI = ({ stories, onLoadMore, noMoreStories }) => (
    <React.Fragment>
      <div className="desktop-only">
        {stories.map(story => (
          <HorizontalStoryCard
            story={story}
            key={story.id}
            boxPadding={false}
            showSection
            increaseFontSize
          />
        ))}
      </div>
      <div className="mobile-only" styleName="card-padding">
        {stories.map(story => (
          <CommonStoryCard story={story} key={story.id} showSection />
        ))}
      </div>
      {data.stories.length >= 10 && !noMoreStories && (
        <div styleName="load-more">
          <LoadMoreButton onClick={onLoadMore} />
        </div>
      )}
    </React.Fragment>
  );
  return (
    <>
      <div styleName="tagName">{tagName}</div>
      <div styleName="wrapper">
        <div>
          {data.stories.length ? (
            <LoadMoreStoriesBase
              template={TagPageUI}
              fields={storyFields}
              params={{ "tag-slugs": data.tag.slug }}
              data={data}
              numStoriesToLoad={19}
            />
          ) : (
            <div>No stories found !!</div>
          )}
        </div>
        <div className="desktop-only">
          <Trending stories={trendingStories} collection={collection} />
        </div>
      </div>
    </>
  );
};
TagPage.propTypes = {
  data: PropTypes.object,
  stories: PropTypes.object,
  noMoreStories: PropTypes.bool,
  onLoadMore: PropTypes.func
};
