import React from "react";
import Explainer from "./Explainer";
import { Link } from "@quintype/components";
import "../../index.m.css";

export default function Home() {
  return (
    <div styleName="page">
      <main styleName="hero-section">
        <div styleName="hero-section-div">
          <div styleName="hero-section-img">
            <img
              src="https://images.assettype.com/newslaundry/2023-08/b9b4b721-7500-47a0-9081-d58871f56823/biascope_2.png"
              alt="MIDa Biascope Hero Image"
            />
            <div styleName="top-cta-1">
              <Link href={"/voting"} styleName="ctav" id="vote-button-2">
                Cast your vote now <span> ➜</span>
              </Link>
            </div>
          </div>

          <div styleName="hero-section-content">
            <h1 styleName="title">Media Biascope</h1>
            <p styleName="border-bottom"></p>
            <p styleName="description">
              No one is totally unbiased or objective. We all have some, however
              little, predilection, prejudice or leaning. Truly great reporters
              and editors have a level of self-awareness and open-mindedness
              where they attempt to correct for their biases and let the facts
              of a story dictate their reports.
              <br />
              <br />
              While we have some kind of corrective mechanisms at Newslaundry
              where we all try to compensate for each other's biases, you can
              help us too. Hold the mirror to us and, while you’re at it, let’s
              do the same for others in the news business too. On this
              ideological spectrum you can plot journalists and commentators
              based on their commentary, social media posts and body of work.
              <br />
              <button
                style={{
                  fontSize: "15px",
                  backgroundColor:
                    "var(--component-background-accent-solid-default)",
                  textDecoration: "none",
                  border: "0px",
                  color: "var(--text-neutral-default)",
                  cursor: "pointer",
                  marginLeft: "-6px",
                  padding: "4px",
                  borderRadius: "4px"
                }}
                onClick={() => {
                  const section = document.querySelector("#methodology");
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                  });
                }}
              >
                <u>Read more</u>
              </button>
              <br />
            </p>
            <div styleName="top-cta-2">
              <Link href={"/voting"} styleName="ctav" id="vote-button-2">
                Cast your vote now <span> ➜</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <div styleName="section">
        <div styleName="container">
          <Explainer />
        </div>
      </div>
    </div>
  );
}
