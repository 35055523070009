import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { AccessType } from "@quintype/components";
import { parseUrl } from "query-string";
import Loader from "../../../components/atoms/loader";
import IntegrationData from "../../../../../config/integrations.js";

import "./hindi-paywall-banner.m.css";

const HindiPaywallBanner = () => {
  const state = useSelector(state => state);
  const [isHindi, setIsHindi] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const member = useSelector(state => {
    return get(state, ["member"], null);
  });
  //const authUrl = "localhost:3000"
  const hasActiveSubscription = useSelector(state =>
    get(state, ["fetchSubscription", "isActive"], null)
  );
  const { qt } = useSelector(state => state);
  const authHost = qt.config.publisher.publisher.auth_host;
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlanObj, setSelectedPlanObj] = useState({});
  const [hindiPlan, SetHindiPlan] = useState("3 months subscription");

  const fetchSubscription = useSelector(state => state.fetchSubscription);

  useEffect(() => {
    setIsHindi(global.location.host.includes("hindi"));
    if (state.subscriptions && state.subscriptions.length) {
      // console.log(state.subscriptions); //.filter((obj) => obj.name === "Angel Subscriber Plan")[0].subscription_plans.filter((obj) => obj.title === selectedPlan)[0]);

      let planObj = state.subscriptions.filter(
        obj => obj.name === "Hindi subscription plan"
      )[0];
      // console.log({ planObj });
      setSelectedPlanObj(planObj);
      // if( planObj && planObj.subscription_plans)  {
      //   planObj = planObj.subscription_plans.filter((obj) => obj.title === selectedPlan)[0]
      //   setSelectedPlanObj(planObj);}
    }
  }, [state]);

  let successUrl = authHost; //global.location;
  const handleNotMember = async () => {
    const params = parseUrl(qt.currentPath);
    const callbackUrl = get(
      params,
      ["query", "callback-url"],
      global.location && global.location.origin
    );
    const redirectUrl = get(
      params,
      ["query", "redirect-url"],
      global && global.location && global.location.href
    );
    //console.log(`${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`, "url")
    window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  };
  const handlePayment = async (
    initAccessType,
    initRazorPayPayment,
    planName
  ) => {
    //console.log("insssss");
    //console.log("plan name", { planName });
    if (planName == "3 months subscription ") setLoading(true);
    else setLoading2(true);
    //pushToDataLayer("story_paywall_user_action", props.url, "subscribe");

    /* Redirect to login if not logged in */
    if (!member) {
      handleNotMember();
    }

    if (true) {
      //console.log("insiiiideee");
      /* Initiate razorpay */
      if (selectedPlanObj && selectedPlanObj.subscription_plans) {
        const correctPlanObj = selectedPlanObj.subscription_plans.filter(
          obj => obj.title === planName
        )[0];
        const options = {
          argType: "options",
          selectedPlan: correctPlanObj,
          planType: "standard",
          couponCode: "",
          successUrl: successUrl,
          recipientSubscriber: {
            recipient_identity_provider: "email"
          }
        };
        // console.log({ options });
        // console.log(selectedPlanObj.subscription_plans);
        //   setSelectedPlanObj(planObj);}

        initRazorPayPayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            await handlePaymentSuccess(res);
          })
          .catch(error => {
            console.error(`Razorpay error: ${error}`);
            if (error.message === "user closed the payment") {
              setError("User closed the payment.");
            } else {
              setError("Oops! Something went wrong");
            }
            setLoading(false);
            setLoading2(false);
          });
      }
    }
  };

  const handlePaymentSuccess = async res => {
    setLoading(false);
    setSuccessMsg(
      "Congratulations!! Payment Successful. Allow us couple of minutes to map your subscription."
    );

    //window.location = successUrl;
  };

  const integrationsConfig = IntegrationData();
  const pub = useSelector(state => get(state, ["qt", "config", "publisher"]));
  const email = get(member, ["email"], "");
  const phone = get(member, ["metadata", "phone"], "");
  const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);
  const enableAccesstype = get(
    pub,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  return (
    <>
      <div styleName="hindi-wrapper">
        <div styleName="hindi-paywall">
          <div styleName="content">
            {/* <h1>Your Title</h1>
        <p>Your content goes here.</p> */}
            <div styleName="left-container">
              <h1>स्वतंत्र पत्रकारिता को सहयोग करें</h1>
              <p>
                न्यूज़लॉन्ड्री हिंदी को आपके समर्थन की जरूरत है. हमारी टीम हिंदी
                के सब्सक्राइबर्स के लिए एक नया ऑफर लेकर आई है. लगभग 50 प्रतिशत
                डिस्काउंट के साथ यह ऑफर सिर्फ कुछ दिनों के लिए उपलब्ध होगा. अपने
                मनमुताबिक प्लान चुनें और गर्व से कहें मेरे ख़र्च पर आज़ाद हैं
                ख़बरें.{" "}
              </p>
              {/* <p styleName="more-text">
                  न्यूजलॉन्ड्री जनहित में खबरें प्रस्तुत करता है, विज्ञापनदाताओं
                  से नहीं। सब्सक्राइब करके इस मिशन का समर्थन करें। चयन आपका है।
                  सब्सक्राइब करके आप न केवल प्रीमियम लेख और वीडियो तक पहुंच
                  पाएंगे, बल्कि विशेष कार्यक्रमों और वेबिनार्स में भी शामिल
                  होंगे। हमारी रिपोर्टिंग टीम से सीधे बातचीत का मौका भी मिलेगा।
                  न्यूजलॉन्ड्री जनहित में खबरें प्रस्तुत करता है, विज्ञापनदाताओं
                  से नहीं। न्यूजलॉन्ड्री जनहित में खबरें प्रस्तुत करता है,
                  विज्ञापनदाताओं से नहीं। न्यूजलॉन्ड्री जनहित में खबरें प्रस्तुत
                  करता है, विज्ञापनदाताओं से नहीं।{" "}
                </p> */}
              <div styleName="hindi-benefits">
                <p style={{ height: "30px" }}>
                  <span style={{ fontSize: "25px", marginRight: "3px" }}>
                    📹
                  </span>{" "}
                  एनएल टीम से सीधे मुलाकात का मौका
                </p>
                <p style={{ height: "30px" }}>
                  <span style={{ fontSize: "25px", marginRight: "3px" }}>
                    🔓
                  </span>{" "}
                  पेवॉल कंटेंट की एक्सेस
                </p>
                <p style={{ height: "30px" }}>
                  <span style={{ fontSize: "25px", marginRight: "3px" }}>
                    ✨
                  </span>{" "}
                  सबस्क्राइबर्स के लिए विशेष ऑफर्स
                </p>
              </div>
            </div>
            <div styleName="right-container">
              <div styleName="option-two">
                <h1>3 महीने सब्सक्रिप्शन</h1>
                <div style={{ margin: "auto" }} styleName="price-wrapper">
                  <p style={{ textDecoration: "line-through", color: "gray" }}>
                    ₹900
                  </p>
                  <p>₹499</p>
                </div>
                {/* <button styleName="ctav">सब्सक्राइब करें</button> */}
                <AccessType
                  enableAccesstype={enableAccesstype}
                  isAccessTypeCampaignEnabled={true}
                  isStaging={false}
                  accessTypeKey={accessTypeKey}
                  accessTypeBkIntegrationId={accessTypeBkIntegrationId}
                  email={email}
                  phone={phone}
                >
                  {({ initAccessType, initRazorPayPayment }) => {
                    return (
                      <>
                        {loading ? (
                          <button styleName="ctav">
                            <Loader color="white" />
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              handlePayment(
                                initAccessType,
                                initRazorPayPayment,
                                "3 months subscription "
                              )
                            }
                            styleName="ctav"
                          >
                            सब्सक्राइब करें
                          </button>
                        )}
                      </>
                    );
                  }}
                </AccessType>
              </div>
              <div styleName="option-one">
                <h1>6 महीने सब्सक्रिप्शन</h1>
                <div style={{ margin: "auto" }} styleName="price-wrapper">
                  <p style={{ textDecoration: "line-through", color: "gray" }}>
                    ₹1800
                  </p>
                  <p>₹999</p>
                </div>
                {/* <button styleName="ctav">सब्सक्राइब करें</button> */}
                <AccessType
                  enableAccesstype={enableAccesstype}
                  isAccessTypeCampaignEnabled={true}
                  isStaging={false}
                  accessTypeKey={accessTypeKey}
                  accessTypeBkIntegrationId={accessTypeBkIntegrationId}
                  email={email}
                  phone={phone}
                >
                  {({ initAccessType, initRazorPayPayment }) => {
                    return (
                      <>
                        {loading2 ? (
                          <button styleName="ctav">
                            <Loader color="white" />
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              handlePayment(
                                initAccessType,
                                initRazorPayPayment,
                                "6 months subscription "
                              )
                            }
                            styleName="ctav"
                          >
                            सब्सक्राइब करें
                          </button>
                        )}
                      </>
                    );
                  }}
                </AccessType>
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && <div styleName="error-message">{error}</div>}
      {successMsg && <div styleName="success-message">{successMsg}</div>}
    </>
  );
};

HindiPaywallBanner.propTypes = {
  collection: PropTypes.object
};

export { HindiPaywallBanner };
