/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { differenceInDays } from "date-fns";
import get from "lodash/get";
import { parseUrl } from "query-string";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  IS_OPEN_LOGIN_FORM,
  PAYMENT_CHECKOUT_PAGE,
  SELECTED_PLAN
} from "../../helper/actions";
import { COMPRESS_PARAMS_MEDIUM } from "../../../image-constants";
import { VideoModal } from "../video-modal";
import "./project-container.m.css";

const ProjectContainerBase = props => {
  const [showUpdates, setShowUpdates] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showShare, setShowShare] = React.useState(false);

  const currency = get(props, ["subscriptions", "currency"], "");
  const currencySymbol = currency === "INR" ? "₹" : "$";
  const targetAmount = get(props, ["subscriptions", "target_amount"], 0) / 100;
  const completedAmount = get(props, ["subscriptions", "collected_amount"], 0);
  const subscriptionGroupId = get(props, ["subscriptions", "id"]);
  const barWidth =
    completedAmount > targetAmount
      ? 100
      : Math.floor((completedAmount / targetAmount) * 100);
  const plans = get(props, ["subscriptions", "subscription_plans"], []);
  const options = plans.map((plan, index) => {
    const currency = plan.price_currency;
    const currencySymbol = currency === "INR" ? "₹" : "$";
    const label = plan.title + " - " + currencySymbol + plan.price_cents / 100;
    return { value: `${JSON.stringify(plan)}`, label: label };
  });

  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : null,
        color: isDisabled
          ? null
          : isSelected
          ? "#c00"
          : isFocused
          ? "#000"
          : null
      };
    }
  };

  const { name } = props.subscriptions;
  const { imageUrl, summary, videoId, updates, reporters, endDate } =
    props.subscriptions.description;

  // console.log({ imageUrl, summary, videoId, updates, reporters, endDate });

  let differenceDate = null;

  if (endDate) {
    differenceDate = differenceInDays(
      new Date(endDate).toDateString(),
      new Date().toDateString()
    );
  }

  const [selectedSubscription, setSelectedSubscription] = React.useState({});

  function handlePlanChange(e, subscriptionGroupId) {
    const currentPlan = JSON.parse(e.value);
    const currentState = Object.assign({}, selectedSubscription, {
      [subscriptionGroupId]: currentPlan
    });
    setSelectedSubscription(currentState);
  }

  function handleSubscriptionClick(e, subscriptionGroup) {
    e.preventDefault();
    e.stopPropagation();

    if (!props.member) {
      // this.props.manageLoginForm(true);
      const params = parseUrl(props.currentPath);
      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${props.authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${props.authHost}/user-login`;
      return false;
    }

    const firstPlan = get(subscriptionGroup, ["subscription_plans", 0]);
    const selectedSubscriptionPlan = get(
      selectedSubscription,
      [subscriptionGroup.id],
      firstPlan
    );
    props.selectedPlan(selectedSubscriptionPlan, props.redirectUrl);
    // props.navigateTo(`/payment-checkout?ref=sena-section-page`);
    props.navigateTo(
      `/campaign/checkout?plan=${selectedSubscriptionPlan.id}&group=${selectedSubscriptionPlan.subscription_group_id}&ref=sena-section-page`
    );
    return true;
  }
  return (
    <div styleName="wrapper">
      <div>
        <h2
          styleName="headline"
          className="collection-name"
          // style={{ color: "black" }}
        >
          {name}
        </h2>
        <div
          styleName="border"
          className="collection-border"
          // style={{ borderBottomColor: "#ec2227" }}
        />
        <br />
      </div>
      {showModal && (
        <VideoModal videoId={videoId} handleClose={() => setShowModal(false)} />
      )}
      <div styleName="top-wrapper">
        <div styleName="left">
          {videoId && (
            <div
              styleName="play"
              onClick={() => setShowModal(true)}
              aria-label="Click to play nl-sena video"
            >
              <i className="material-icons" aria-hidden="true">
                play_circle
              </i>
            </div>
          )}
          <img
            src={`${imageUrl}${COMPRESS_PARAMS_MEDIUM}`}
            onClick={() => setShowModal(true)}
          />
        </div>
        <div styleName="right">
          <div styleName="progress-wrapper">
            <div
              styleName="progress-bar"
              style={{
                width: barWidth + "%",
                backgroundColor: !props.showBottom ? "#1ad193" : ""
              }}
              aria-label={`Bar with width ${barWidth}% `}
            ></div>
          </div>

          {props.showBottom && (
            <>
              <div styleName="amount-container">
                <div styleName="amount-wrapper">
                  <div styleName="amount">
                    {currencySymbol + completedAmount}
                  </div>

                  <div styleName="goal">Raised so far</div>
                </div>

                <div styleName="amount-wrapper">
                  <div styleName="amount select">
                    {currencySymbol + targetAmount}
                  </div>

                  <div styleName="goal">Goal</div>
                </div>
              </div>
              {differenceDate === 0 ? (
                <div styleName="countdown-wrapper">The campaign ends today</div>
              ) : differenceDate > 0 ? (
                <div styleName="countdown-wrapper">
                  {differenceDate} days left
                </div>
              ) : null}
              {options && options.length > 0 && (
                <div styleName="list-wrapper">
                  <Select
                    options={options}
                    onChange={e => handlePlanChange(e, subscriptionGroupId)}
                    defaultValue={options[0]}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={colourStyles}
                    isSearchable={false}
                    inputProps={{
                      "aria-label": "select the appropriate level"
                    }}
                  />
                </div>
              )}
              <div styleName="btn-wrapper">
                {props.subscriptions &&
                props.subscriptions.description &&
                props.subscriptions.description.type === "legal_fund" ? (
                  <button
                    styleName="btn"
                    onClick={e =>
                      handleSubscriptionClick(e, props.subscriptions)
                    }
                  >
                    Support
                  </button>
                ) : (
                  <button
                    styleName="btn"
                    onClick={e =>
                      handleSubscriptionClick(e, props.subscriptions)
                    }
                  >
                    {/* Join&nbsp;NL&nbsp;Sena */} Contribute
                  </button>
                )}
              </div>
            </>
          )}

          {!props.showBottom && (
            <React.Fragment>
              <div styleName="complete-wrapper">
                <div styleName="goal">{barWidth}% Funded</div>
                <div styleName="amount">{currencySymbol + targetAmount}</div>
              </div>
              <br />
              <p styleName="complete-text">The project is completed</p>
              {/* {targetAmount === completedAmount && (
                <p styleName="complete-text">The project is completed</p>
              )} */}
            </React.Fragment>
          )}

          {reporters && (
            <div styleName="reporters">
              Reporters: <span>{reporters}</span>
            </div>
          )}
          <br />

          <div
            styleName="share"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <button aria-label="Click to watch">
              Watch{" "}
              <i className="material-icons-outlined" aria-hidden="true">
                play_circle_outline
              </i>
            </button>
          </div>
          <div
            styleName="share"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://newslaundry.com${props.currentPath}`
              );
              setShowShare(true);
            }}
          >
            <button aria-label="Click to share this nl-sena">
              Share <i className="material-icons-outlined">share</i>
              {showShare && <p>Link copied.</p>}
            </button>
          </div>
        </div>
      </div>

      <div styleName="bottom-wrapper">
        <div styleName="tabs">
          <span
            styleName={!showUpdates ? "active" : ""}
            onClick={() => setShowUpdates(false)}
          >
            Project
          </span>
          <span
            styleName={showUpdates ? "active" : ""}
            onClick={() => setShowUpdates(true)}
          >
            Updates
          </span>
        </div>
        <hr />
        {showUpdates ? (
          <div styleName="updates">
            {updates &&
              updates.map((item, i) => {
                return (
                  <div key={i}>
                    <h4>{item.date}</h4>
                    <p>{item.event}</p>
                    <br />
                  </div>
                );
              })}
            {!updates && (
              <div>
                <p>No updates found</p>
              </div>
            )}
          </div>
        ) : (
          <div
            styleName="story"
            dangerouslySetInnerHTML={{ __html: summary }}
          ></div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function (payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  selectedPlan: function (plan, redirectUrl) {
    dispatch({
      type: SELECTED_PLAN,
      plan: plan,
      planType: "campaign",
      redirectUrl: redirectUrl
    });
  },
  paymentCheckout: function (payload) {
    dispatch({
      type: PAYMENT_CHECKOUT_PAGE,
      payload: payload
    });
  }
});

const mapStateToProps = state => {
  return {
    config: get(state, ["qt", "config"], {}),
    member: get(state, ["member"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    )
  };
};

export const ProjectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectContainerBase);
