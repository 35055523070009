import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { Link, ResponsiveImage } from "@quintype/components";
import { getSlug } from "../../../utils";

import "./common-collection-card.m.css";
// import { Headline } from "../../atoms/headline";

const CommonCollectionCard = ({ collection, config, sectionSlug }) => {
  const slug = sectionSlug || getSlug(collection, config);
  const collectionName = get(collection, ["name"], "");
  const collectionSummary = get(collection, ["summary"], "");
  const defaultImgS3Key = get(
    config,
    ["publisher", "publisher", "defaulImgS3key"],
    ""
  );
  const imgS3Key = get(
    collection,
    ["metadata", "cover-image", "cover-image-s3-key"],
    defaultImgS3Key
  );
  const imgMetadata = get(
    collection,
    ["metadata", "cover-image", "cover-image-metadata"],
    {}
  );

  return (
    <div styleName="card-container">
      {/* temp fix for tippani link in video section newslaundry */}
      <Link
        href={
          slug === "/collection/nl-tippani"
            ? "https://hindi.newslaundry.com/collection/nl-tippani-video-newslaundry-hindi"
            : slug
        }
        styleName="hero-image"
        className="hero-image"
        aria-label={`Go to ${collectionName}`}
      >
        <figure className="image">
          <ResponsiveImage
            slug={imgS3Key}
            metadata={imgMetadata}
            aspectRatio={[370, 208]}
            defaultWidth={259}
            widths={[250, 480]}
            className="responsive-img"
            alt="Article image"
          />
        </figure>
      </Link>
      <div styleName="text-container">
        {/* temp fix for tippani link in video section newslaundry */}
        <Link
          href={
            slug === "/collection/nl-tippani"
              ? "https://hindi.newslaundry.com/collection/nl-tippani-video-newslaundry-hindi"
              : slug
          }
          aria-label={`${collectionName}`}
        >
          <h3 styleName="headline">
            <bdi dangerouslySetInnerHTML={{ __html: collectionName }} />
          </h3>
        </Link>
        <p styleName="subheadline">
          <bdi dangerouslySetInnerHTML={{ __html: collectionSummary }} />
        </p>
      </div>
    </div>
  );
};

CommonCollectionCard.propTypes = {
  collection: PropTypes.object,
  config: PropTypes.object,
  sectionSlug: PropTypes.string
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {})
});

export default connect(mapStateToProps)(CommonCollectionCard);
