/* eslint-disable max-len */
export const MEET_THE_EXPERTS_TEAM = [
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/mgfmn956/DHARMESH.jpg",
    alt: "Dharmesh Shah",
    designation: "Environmental health expert",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/chawgi5l/VIMALENDU.jpg",
    alt: "Vimlendu Jha",
    designation: "Environmentalist",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/6mkk5q56/SUNIL.jpg",
    alt: "Sunil Dahiya",
    designation: "Emissions expert",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/t9x3z0iw/SANJAY.jpg",
    alt: "Sanjay Upadhyay",
    designation: "Environmental law expert",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/wvgdt212/BRAJESH.jpg",
    alt: "Brijesh Kumar",
    designation: "Former bureaucrat",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/s788n3xd/MANOJ-KUMAR.jpg",
    alt: "Manoj Kumar",
    designation: "Air policy expert",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/ry34j39i/JAI-DHAR.jpg",
    alt: "Jai Dhar Gupta",
    designation: "Environmental entrepreneur",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/f1q7c8wu/KHILNANI.jpg",
    alt: "Dr GC Khilnani",
    designation: "Pulmonologist",
  },
  {
    src: "https://images.assettype.com/newslaundry/2024-12-23/gtinguxi/SANJEEEV.jpg",
    alt: "Dr Sanjeev Bagai",
    designation: "Paediatric nephrologist",
  },
  {
    src: "https://images.assettype.com/newslaundry/2025-01-16/9pyg0wal/1554394671836.jpeg",
    alt: "Nivedita Khandekar",
    designation: "Senior journalist",
  },
  {
    src: "https://images.assettype.com/newslaundry/2025-01-22/na6ujm1v/image.png",
    alt: "Bhavreen Kandhari",
    designation: "Clean air campaigner",
  },
];

export const FAQ = [
  {
    question: "Who is more at risk?",
    answer:
      "Long-term exposure harms everyone. Though pregnant women, the elderly, children and patients with cardiovascular, cerebrovascular or respiratory disease must reduce short-term exposure to toxic air.",
  },
  {
    question: "What can the government do?",
    answer:
      "It will need national, regional and local cooperation for a resolution. Governments can monitor emissions, put improved urban planning into effect, and promote sustainable consumption.",
  },
  {
    question: "What is an air quality index?",
    answer:
      "AQI is a tool to gauge the concentration of pollutants in outdoor air. Devised by the US-based Environment Protection Agency, it measures the level of five major air pollutants, including ground-level ozone, particulate matter, carbon monoxide, nitrogen dioxide, and sulfur dioxide. The AQI is a beneficial scale especially for those suffering from illnesses caused or exacerbated by exposure to air pollution.",
  },
  {
    question: "How is it classified into different categories?",
    answer:
      "Different agencies in different countries use different point scales to measure air quality. In India, agencies like the Central Pollution Control Board use a 500-point AQI scale. With 0 to 50 (good), 51-100 (satisfactory), 101-200 (moderate), 201-300 (poor), 301-400 (very poor), and 401-500 (severe) as the different categories of air quality. Prolonged exposure to the poor category causes breathing discomfort to most people while the very poor category can trigger respiratory illness.",
  },
  {
    question:
      "Why does India suffer an air pollution crisis at the onset of every winter?",
    answer:
      "While most emissions remain at similar levels throughout the year, pollution levels reach alarming levels during winter months especially in northern India. Winter months bring with themselves certain meteorological conditions – such as temperature inversion in which cool air is trapped near the ground, and low wind speeds – that prevents pollutants in the air from rising into the atmosphere. This limited dispersal of accumulated pollutants is what causes air pollution. Episodic winter events like burning of firecrackers in Diwali, stubble burning in states like Punjab and Haryana release more pollutants into the air.",
  },
  {
    question: "What is particulate matter? What are PM2.5 and PM10?",
    answer:
      "Particulate matter or PM is a blanket term used to describe air-borne particles like smoke and soot, emitted from sources like industrial activities, vehicles, thermal power plants, waste incinerators, fires and dust. The particle size of these pollutants can be less than 2.5 microns (PM2.5) in diameter or more than 10 microns (PM10). Differently-sized particles affect different parts of the respiratory system. While particles larger than 10 microns get trapped in the nose or throat affecting the upper respiratory tract, particles less than 2.5 microns reach the lungs and can lead to pulmonary diseases.",
  },
  {
    question: "Can air pollution be reversed?",
    answer:
      "Several cities across the world have been successful in not only reducing air pollution but also reversing it. Once known as the smog capital of the world, Beijing has cut air pollution by 50 percent since 2013, according to the Air Quality Life Index, produced by University of Chicago’s Energy Pollution Institute.",
  },
  {
    question: "What is the government doing to tackle the problem?",
    answer:
      "It has implemented several measures, including the National Clean Air Programme. But it’s far behind the target.",
  },
  {
    question: "How can I help?",
    answer:
      "An individual has very limited options to restrict exposure to pollutants. But together, a lot can be achieved. That’s why we have launched a collaboration between readers, experts and team Newslaundry. It’s called the Fight To Breathe. It’s to ensure this existential crisis remains a talking point beyond the life of a news cycle.",
  },
  {
    question: "How can I join the Fight To Breathe?",
    answer:
      "In three easy ways. You can either file RTIs and share your findings with submissions@newslaundry.com. Or you can repost our campaign content with the hashtag #FightToBreathe, with a snapshot of the reading from your nearest air monitoring station. Or you can contribute an amount of your choice to power this campaign. (You are truly a captain if you do all of the above.) Here’s a detailed explainer on how to go about these steps.",
  },
  {
    question: "How long will this campaign continue?",
    answer: "For one year. Hope you stay with us until the end.",
  },
];
