/* eslint-disable react/prop-types */
import { AccessType } from "@quintype/components";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IntegrationData from "../../../../../../config/integrations.js";
import { createGiftSubscription } from "../../../../cartoon-mango.js";
import { isValidEmail } from "../../../../utils";
import Loader from "../../../atoms/loader";
import { SubscriptionCouponForm } from "../../../molecules/subscription-coupon-form";
import { BLOCKS } from "../../subscription-page/constants";
import { PaymentFaq } from "./payment-faq";

import "./payment.m.css";

export const Payment = ({
  member,
  planId,
  activeStep,
  setActiveStep,
  isGift
}) => {
  const subscriptionGroups = useSelector(state =>
    get(state, ["subscriptions"], [])
  );

  const subscriptionData = BLOCKS.find(
    item => Number(item.planId) === Number(planId)
  );

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("upi");
  const [couponCode, setCouponCode] = useState("");
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  // const [isGift, setIsGift] = useState(false);

  const userCountry = useSelector(state =>
    get(state, ["fetchUserCountry"], {
      country_code: "",
      country_code_alias: "",
      country_name: "",
      user_timezone: ""
    })
  );

  const toShowPaymentOptions =
    userCountry.country_code === "IN"
      ? ["razorpay", "upi"]
      : // ? ["stripe", "paypal"]
        ["stripe", "upi", "paypal"];

  // console.log({ toShowPaymentOptions });

  useEffect(() => {
    if (
      !planId ||
      !BLOCKS.find(item => Number(item.planId) === Number(planId))
    ) {
      window.location.href = "/subscription";
    } else {
      console.log("INSIDE 1");
      const selectedGroup = subscriptionGroups.find(
        item => Number(item.id) === Number(subscriptionData.groupId)
      );

      if (selectedGroup) {
        setSelectedPlan(
          selectedGroup.subscription_plans.find(
            plan => Number(plan.id) === Number(planId)
          )
        );
      }

      if (userCountry.country_code === "IN") {
        setSelectedPaymentOption("upi");
      } else {
        setSelectedPaymentOption("upi");
      }

      // console.log("HREF", window.location.href);
      // console.log("SPLIT FN", window.location.href.split("&gift="));
      // console.log("SPLIT FN 2", window.location.href.split("&gift=")[1]);

      // if (window.location.href.split("&gift=")[1]) {
      //   console.log("INSIDE 2");

      //   setIsGift(true);
      //   setSelectedPaymentOption("razorpay");
      // }
    }
  }, []);

  useEffect(() => {
    if (isGift) {
      setSelectedPaymentOption("razorpay");
    }
  }, [isGift]);

  useEffect(() => {
    const selectedGroup = subscriptionGroups.find(
      item => Number(item.id) === Number(subscriptionData.groupId)
    );

    if (selectedGroup) {
      setSelectedPlan(
        selectedGroup.subscription_plans.find(
          plan => Number(plan.id) === Number(planId)
        )
      );
    }
  }, [subscriptionGroups]);

  return (
    <React.Fragment>
      <div styleName="wrapper" className="container">
        <PlanDetails
          subscriptionData={subscriptionData}
          selectedPlan={selectedPlan}
          isValidCoupon={isValidCoupon}
          setIsValidCoupon={setIsValidCoupon}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          discountedPrice={discountedPrice}
          setDiscountedPrice={setDiscountedPrice}
        />
        <PaymentMethod
          planId={planId}
          paymentOption={selectedPaymentOption}
          setPaymentOption={setSelectedPaymentOption}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          member={member}
          isValidCoupon={isValidCoupon}
          couponCode={couponCode}
          discountedPrice={discountedPrice}
          isGift={isGift}
          selectedPlan={selectedPlan}
          toShowPaymentOptions={toShowPaymentOptions}
        />
      </div>
      <PaymentFaq />
    </React.Fragment>
  );
};

const PaymentMethod = props => {
  // console.log({ PaymentMethodProps: props });

  const [error] = React.useState(null);
  const [receipentEmail, setReceipentEmail] = useState();

  const handleChange = option => {
    props.setPaymentOption(option);
  };

  useEffect(() => {
    // console.log(props.paymentOption, "optinnnn")
    if (props.isGift) {
      setReceipentEmail(
        JSON.parse(window.localStorage.getItem("giftData")).recipient_email
      );
    }
  }, [props.isGift]);

  return (
    <div styleName="payment-methods-wrapper">
      {props.isGift && (
        <input
          type="email"
          value={receipentEmail}
          onChange={e => setReceipentEmail(e.target.value)}
          placeholder="Email adress of giftee"
          disabled
        />
      )}
      {props.toShowPaymentOptions.includes("stripe") ? (
        <>
          <div styleName="method" onClick={() => handleChange("stripe")}>
            <input type="radio" checked={props.paymentOption === "stripe"} />
            <label>Credit/Debit Card</label>
            <div styleName="label-img-wrapper">
              <img
                alt="paytm"
                style={{
                  height: "2.5rem",
                  marginBottom: "-0.4em"
                }}
                styleName="radio-label-img"
                src="https://img.icons8.com/fluency/144/000000/visa.png"
              />
              <img
                alt="paytm"
                style={{
                  height: "2.5rem",
                  marginBottom: "-0.4em"
                }}
                styleName="radio-label-img"
                src="https://img.icons8.com/color/240/000000/mastercard.png"
              />
            </div>
          </div>
          {props.paymentOption === "stripe" && (
            <p styleName="stripe-recurring-text">
              Also, you may see an address field because of an RBI Mandate for
              international payments. The address you add is only saved with the
              payment gateway and cannot be accessed or saved by us.
            </p>
          )}
        </>
      ) : null}
      {props.toShowPaymentOptions.includes("upi") && !props.isGift ? (
        <div styleName="method" onClick={() => handleChange("upi")}>
          <input type="radio" checked={props.paymentOption === "upi"} />
          <label>Credit/Debit Cards, UPI</label>
          <div styleName="label-img-wrapper">
            <img
              alt="paytm"
              style={{
                height: "2.5rem"
                // marginBottom: "-0.45rem"
              }}
              styleName="radio-label-img"
              src="https://images.assettype.com/newslaundry/2022-04/aef00c00-c403-470e-918b-72426b5efdf7/upi__1_.png"
            />
            <img
              alt="paytm"
              style={{
                height: "1.2rem"
                // marginBottom: "0.4em"
              }}
              styleName="radio-label-img"
              src="https://thumbor-stg.assettype.com/newslaundry/2021-07/21aac1e9-d2f2-41f5-b6c6-22700fe8a59e/paytmlogo.png"
            />
            <img
              alt="google pay"
              styleName="radio-label-img"
              src="https://images.assettype.com/newslaundry/2020-10/1b086995-83ca-4388-b0ad-88383e02c2da/googlepay.png"
            />
            <img
              alt="phone pe"
              styleName="radio-label-img"
              src="https://images.assettype.com/newslaundry/2020-10/f104aa32-abb5-48d3-84ea-34b7cfcbf20f/phonepe.png"
            />
          </div>
        </div>
      ) : null}
      {props.toShowPaymentOptions.includes("razorpay") ? (
        <div styleName="method" onClick={() => handleChange("razorpay")}>
          <input type="radio" checked={props.paymentOption === "razorpay"} />
          <label>{props.isGift ? "UPI, " : ""}Wallets, Netbanking, Cards</label>
          <div styleName="label-img-wrapper">
            {/* <img
              alt="netbanking"
              style={{
                height: "4rem"
                // marginTop: "-0.6em",
                // marginBottom: "-1em"
              }}
              styleName="radio-label-img"
              src="https://static.thenounproject.com/png/634436-200.png"
            /> */}
          </div>
        </div>
      ) : null}
      {props.toShowPaymentOptions.includes("paypal") && (
        <div onClick={() => handleChange("paypal")} styleName="method">
          <input type="radio" checked={props.paymentOption === "paypal"} />
          <label>Paypal</label>
          <div styleName="label-img-wrapper">
            <img
              alt="paytm"
              style={{
                height: "2.5rem",
                marginBottom: "-0.4em"
              }}
              styleName="radio-label-img"
              src="https://img.icons8.com/color/96/000000/paypal.png"
            />
          </div>
        </div>
      )}
      {props.paymentOption === "paypal" && !props.isGift ? (
        <p>
          Only for subscribers based outside India. You will be charged in USD.
          You can turn off the auto-renewal from the subscriber dashboard
          anytime.
        </p>
      ) : (props.paymentOption === "stripe" || props.paymentOption === "upi") &&
        !props.isGift ? (
        <p>
          Your subscription will be renewed automatically, if your bank supports
          it. You can turn off the auto-renewal from the subscriber dashboard
          anytime
        </p>
      ) : null}

      {error && <small>{error}</small>}
      <Subscribe
        {...props}
        receipentEmail={receipentEmail}
        setReceipentEmail={setReceipentEmail}
      />
    </div>
  );
};

const Subscribe = props => {
  // console.log({ SubscribeProps: props });
  console.log(
    useSelector(state => state),
    "state"
  );

  const allSubscriptions = useSelector(state =>
    get(state, ["subscriptions"], [])
  );
  const { fetchSubscription, member } = useSelector(state => state);

  const [redirectURL, setRedirectURL] = useState("");
  const [successUrl, setSuccessUrl] = useState("");

  useEffect(() => {
    if (global) {
      console.log(new URL(global.location.href), "URL");
      setRedirectURL(new URL(global.location.href));

      const groupDetails =
        data &&
        allSubscriptions &&
        allSubscriptions.find(item => item.id === data.groupId);
      // console.log({groupDetails});
      // console.log({allSubscriptions});
      const planDetails =
        groupDetails &&
        groupDetails.subscription_plans &&
        groupDetails.subscription_plans.find(plan => plan.id === data.planId);

      let groupName = "";
      // console.log({planDetails})

      if (planDetails) {
        if ([113, 760, 804, 805].includes(planDetails.subscription_group_id)) {
          groupName = "gamechanger";
        } else if ([114, 759].includes(planDetails.subscription_group_id)) {
          groupName = "disruptor";
        } else {
          groupName = "defaultgroup";
        }
      }
      // console.log({groupName});
      // console.log({props});

      const s = global.location.href;

      const url = new URL(s);
      url.searchParams.append("status", "success");
      url.searchParams.append(groupName, "true");
      // console.log(url);
      setSuccessUrl(url);
    }
  }, [props]);

  useEffect(() => {
    console.log({ successUrl, redirectURL });
  }, [successUrl, redirectURL]);

  const data = BLOCKS.filter(item => +item.planId === +props.planId)[0];
  // console.log({data});
  // console.log({BLOCKS});

  const groupDetails =
    data &&
    allSubscriptions &&
    allSubscriptions.find(item => item.id === data.groupId);

  const planDetails =
    groupDetails &&
    groupDetails.subscription_plans &&
    groupDetails.subscription_plans.find(plan => plan.id === data.planId);

  const splitDescription =
    (planDetails &&
      planDetails.description &&
      planDetails.description.split(",")) ||
    "";

  // function to get USD planDetails for paypal payment method
  const getUsdPlan =
    groupDetails &&
    groupDetails.subscription_plans.find(
      plan => plan.id === +splitDescription[1]
    );

  // function to select Equivalent One time plan for razorpay
  const selectEquivalentOneTimePlan = () => {
    const splitDescription =
      (planDetails.description && planDetails.description.split(",")) || "";

    const getSelectedPlan = groupDetails.subscription_plans.filter(
      plan => plan.id === +splitDescription[0]
    );
    if (props.isValidCoupon) {
      getSelectedPlan[0].price_cents = props.discountedPrice;
    }
    return getSelectedPlan[0];
  };

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const planLegend = {
    338: {
      name: "Disruptor - Monthly",
      price: "300"
    },
    2632: {
      name: "Disruptor - Quarterly",
      price: "900"
    },
    340: {
      name: "Disruptor - Yearly",
      price: "3000"
    },
    341: {
      name: "Game Changer - Monthly",
      price: "1000"
    },
    343: {
      name: "Game Changer - Yearly",
      price: "10000"
    },
    2033: {
      name: "Disruptor - Student",
      price: "1,500"
    }
  };

  const pushToDataLayer = (event, options = {}) => {
    const { category, paymentOption, discount, selectedPlan } = options;

    const obj = {
      event: event,
      subscription_category: category,
      payment_method: paymentOption,
      discount: discount,
      subscription_plan: selectedPlan.name,
      price: selectedPlan.price,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  const handlePayment = async (
    initAccessType,
    initRazorPayPayment,
    initStripePayment,
    initPaypalPayment,
    validateCoupon
  ) => {
    initAccessType();

    if (!isValidEmail(props.receipentEmail) && props.isGift) {
      setError("Invalid Email");
      return false;
    }

    /* If not active subscriber then Proceed with subscription payment */
    if (props) {
      const obj = {
        category: props.isGift ? "gift_subscription" : "self_subscription",
        paymentOption: props.paymentOption,
        discount: props.isValidCoupon ? props.couponCode : null,
        selectedPlan: planLegend[props.planId.toString()]
          ? planLegend[props.planId.toString()]
          : "null"
      };

      setLoading(true);

      /* Initiate razorpay */
      if (props.paymentOption === "razorpay") {
        pushToDataLayer("subscription_initiatePayment", obj);

        const options = {
          argType: "options",
          selectedPlan: selectEquivalentOneTimePlan(),
          planType: "standard",
          coupon_code: props.isValidCoupon ? props.couponCode : "",
          successUrl: successUrl.href,
          recipientSubscriber: {
            recipient_identity_provider: "email",
            recipient_identity: props.receipentEmail
          }
        };

        initRazorPayPayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            setLoading(false);
            res && (await handlePaymentSuccess(res, successUrl.href));
          })
          .catch(error => {
            setLoading(false);
            const jointSubError = get(error, ["error", "code"], null);

            const getErrorMessage = get(
              error,
              ["error", "message", "subscriber", 0],
              ""
            );
            console.error(`Razorpay one time error:`, { error });
            handlePaymentError(jointSubError || getErrorMessage, obj);
          });
      }

      if (props.paymentOption === "upi") {
        pushToDataLayer("subscription_initiatePayment", obj);

        const options = {
          argType: "options",
          selectedPlan:
            props.isGift || props.isValidCoupon
              ? selectEquivalentOneTimePlan()
              : planDetails,
          planType: "standard",
          coupon_code: props.isValidCoupon ? props.couponCode : "",
          successUrl: successUrl.href,
          recipientSubscriber: {
            recipient_identity_provider: "email",
            recipient_identity: props.receipentEmail
          }
        };

        initRazorPayPayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            setLoading(false);
            res && (await handlePaymentSuccess(res, successUrl.href));
          })
          .catch(error => {
            setLoading(false);
            const jointSubError = get(error, ["error", "code"], null);

            const getErrorMessage = get(
              error,
              ["error", "message", "subscriber", 0],
              ""
            );

            console.error(`Razorpay recurring error:`, { error });
            console.error(`Joint Razorpay recurring error:`, {
              code: error.error.code,
              error: error.error.message
            });

            handlePaymentError(jointSubError || getErrorMessage, obj);
          });
      }

      /* Initiate stripe */
      if (props.paymentOption === "stripe") {
        pushToDataLayer("subscription_initiatePayment", obj);

        const options = {
          argType: "options",
          selectedPlan:
            props.isGift || props.isValidCoupon
              ? selectEquivalentOneTimePlan()
              : planDetails,
          planType: "standard",
          coupon_code: props.isValidCoupon ? props.couponCode : "",
          successUrl: successUrl.href,
          cancelUrl: redirectURL.href.toString(),
          recipientSubscriber: {
            recipient_identity_provider: "email",
            recipient_identity: props.receipentEmail
          }
        };

        initStripePayment(options)
          .then(async res => {
            setLoading(false);
            res && (await handlePaymentSuccess(res, successUrl.href));
          })
          .catch(error => {
            setLoading(false);
            const jointSubError = get(error, ["error", "code"], null);

            const getErrorMessage = get(
              error,
              ["error", "message", "subscriber", 0],
              ""
            );
            console.error(`Stripe error: ${JSON.stringify(error, null, 2)}`);
            console.error(`Joint Stripe error:`, {
              error: error.error.message
            });

            handlePaymentError(jointSubError || getErrorMessage, obj);
          });
      }

      /* Initiate paypal */
      if (props.paymentOption === "paypal") {
        pushToDataLayer("subscription_initiatePayment", obj);

        let usdPlan = {};

        usdPlan = groupDetails.subscription_plans.find(
          plan => plan.id === +splitDescription[1]
        );

        if (props.isValidCoupon) {
          await validateCoupon(usdPlan.id, props.couponCode)
            .then(res => {
              if (res.valid) {
                usdPlan.price_cents = props.discountedPrice;
              }
            })
            .catch(err => console.error(`Paypal coupon error: ${err}`));
        }

        const options = {
          argType: "options",
          selectedPlan:
            props.isGift || props.isValidCoupon ? usdPlan : getUsdPlan,
          planType: "standard",
          coupon_code: props.isValidCoupon ? props.couponCode : "",
          recipientSubscriber: {
            recipient_identity_provider: "email",
            recipient_identity: props.receipentEmail
          },
          returnUrl: successUrl.href,
          cancelUrl: "https://newslaundry.com"
        };
        // console.log({options}, "paypal options")
        initPaypalPayment(options)
          .then(async res => {
            // TODO need to store this invoice responce in store for my account
            setLoading(false);
            res && (await handlePaymentSuccess(res, successUrl.href));
          })
          .catch(error => {
            setLoading(false);
            const jointSubError = get(error, ["error", "code"], null);

            const getErrorMessage = get(
              error,
              ["error", "message", "subscriber", 0],
              ""
            );
            console.error(`Paypal error: ${error}`);
            handlePaymentError(jointSubError || getErrorMessage, obj);
          });
      }
    }
  };

  const handlePaymentSuccess = async (res, successUrl) => {
    setLoading(false);
    if (props.isGift && window.localStorage.getItem("giftData")) {
      await createGiftSubscription(
        JSON.parse(window.localStorage.getItem("giftData"))
      );
    }
    window.localStorage.removeItem("giftData");
    window.location.href = successUrl;
  };

  const handlePaymentError = (getErrorMessage, obj) => {
    pushToDataLayer("payment_failed", obj);

    if (error && error.message === "user closed the payment") {
      setError("User closed the payment.");
    } else if (
      getErrorMessage === "already has active subscription for the same plan"
    ) {
      setError(
        `You already have an active recurring subscription. For support in addition to your current subscription, you can check out and join NL Sena or gift a subscription to someone you know`
      );
    } else if (
      getErrorMessage ===
      "already has active subscription for a recurring plan with same asset"
    ) {
      setError(
        `You already have an active recurring subscription. For support in addition to your current subscription, you can check out and join NL Sena or gift a subscription to someone you know`
      );
    } else if (
      getErrorMessage === "subscription_preview_active_secondary_subscription"
    ) {
      setError(
        `Oops! It seems like you already have a News Minute subscription. Write to subscription@newslaundry.com to get the NL-TNM joint subscription with a discount`
      );
    } else {
      setError("Oops! Something went wrong");
    }
  };

  /*
    Accesstype component initialise  
  */
  const integrationsConfig = IntegrationData();
  const pub = useSelector(state => get(state, ["qt", "config", "publisher"]));
  const email = get(props.member, ["email"], "");
  const phone = get(props.member, ["metadata", "phone"], "");
  const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);
  const enableAccesstype = get(
    pub,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  return (
    <div styleName="subscribe-wrapper">
      <AccessType
        enableAccesstype={enableAccesstype}
        isAccessTypeCampaignEnabled={true}
        isStaging={false}
        accessTypeKey={accessTypeKey}
        accessTypeBkIntegrationId={accessTypeBkIntegrationId}
        email={props.receipentEmail || email}
        phone={phone}
      >
        {({
          initAccessType,
          initRazorPayPayment,
          initStripePayment,
          initPaypalPayment,
          validateCoupon
        }) => {
          return (
            <React.Fragment>
              {loading ? (
                <button>
                  <Loader color="white" />
                </button>
              ) : (
                <button
                  onClick={() =>
                    handlePayment(
                      initAccessType,
                      initRazorPayPayment,
                      initStripePayment,
                      initPaypalPayment,
                      validateCoupon
                    )
                  }
                >
                  Subscribe
                  <br />
                  {!props.discountedPrice ? (
                    <h3>
                      ₹{data && data.price} {data && data.name}
                    </h3>
                  ) : (
                    <h3>
                      <strike>₹{data && data.price} </strike>₹
                      {props.discountedPrice / 100} {data && data.name}{" "}
                    </h3>
                  )}
                </button>
              )}
              <p styleName="error-paragraph">{error}</p>
            </React.Fragment>
          );
        }}
      </AccessType>
    </div>
  );
};

// 340
// const DisruptorYearlyForm = () => {};

const PlanDetails = props => {
  function cutString(inputString) {
    const index = inputString.indexOf("<a href=");

    if (index !== -1) {
      return inputString.substring(0, index);
    }

    return inputString;
  }
  return (
    <div styleName="plan-details-wrapper" aria-label="Your Selected Plan">
      <h2>{props.subscriptionData.groupName}</h2>
      <p>
        <span />
      </p>
      <SubscriptionCouponForm {...props} />
      <p>
        <span />
      </p>
      <ul styleName="benefits" aria-label="Your plan benefits">
        {props.subscriptionData.benefits &&
          props.subscriptionData.benefits.map((benefit, index) => {
            return (
              <li key={index}>
                <i className="material-icons">check</i>
                {cutString(benefit)}
              </li>
            );
          })}
        {props.subscriptionData.plus &&
          props.subscriptionData.plus.map((benefit, index) => {
            return (
              <li key={index}>
                <i className="material-icons">check</i>
                {cutString(benefit)}
              </li>
            );
          })}
      </ul>
      <p>
        <span />
      </p>
      {!props.discountedPrice ? (
        <small>
          To pay: <b>₹{props.subscriptionData.price}</b>
        </small>
      ) : (
        <small>
          To pay: <strike>₹{props.subscriptionData.price}</strike>{" "}
          <b>₹{props.discountedPrice / 100}</b>
        </small>
      )}
      {/* <div></div> */}
    </div>
  );
};
