import React from "react";
import { FaLink, FaTwitter } from "react-icons/fa";
import PropTypes from "prop-types";
import "./team.m.css";
import { Link } from "@quintype/components";
import { COMPRESS_PARAMS_SMALL } from "../../../image-constants";

const TeamMember = ({ member }) => {
  const socials = [];

  if (member.social) {
    for (const soc in member.social) {
      socials.push({
        social: soc,
        url: member.social[soc].url,
        handle: member.social[soc].handle
      });
    }
  }

  return (
    <div styleName="team-member-div">
      <Link href={`/author/${member.slug}`}>
        <img
          src={
            `${member["avatar-url"]}${COMPRESS_PARAMS_SMALL}` ||
            `https://images.assettype.com/newslaundry/2020-02/1c7ca457-3aa7-4b95-be00-1adfb63d87ff/qiMQgIos.png${COMPRESS_PARAMS_SMALL}`
          }
          alt={`${member.name} photo`}
          styleName="team-member-img"
          loading="lazy"
        />
      </Link>
      <div styleName="team-member-description">
        <Link href={`/author/${member.slug}`}>
          <div styleName="team-member-name">{member.name}</div>
        </Link>
        <div styleName="team-member-designation">
          {member.metadata.designation}
        </div>
        <div styleName="team-member-socials">
          {socials.map(social => {
            if (social.social === "twitter") {
              return (
                <a
                  key={social.social}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="see the person's twitter"
                >
                  <FaTwitter color="#1DA1F2" />
                </a>
              );
            }
          })}
          <Link
            href={`/author/${member.slug}`}
            aria-label={`${member.name} author`}
          >
            <FaLink color="#FF4747" />
          </Link>
        </div>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  member: PropTypes.object
};

export default TeamMember;
