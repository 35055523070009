import React from "react";
import get from "lodash/get";
import PT from "prop-types";
// import assetify from "@quintype/framework/assetify";
// import heroImg from "../../../../assets/images/nl-images/nl-sena-page.png";

import "./gift-card.m.css";
import {
  BIRUBAL_GIFTING_NL_CLIP_BOX_SUBSCRIPTION,
  COMPRESS_PARAMS_EXTRA_SMALL,
  COMPRESS_PARAMS_MEDIUM
} from "../../../image-constants";

const GiftCard = ({ firstCard, gift }) => {
  const items = get(gift, ["points"], []);
  // console.log({gift});

  return (
    <>
      {firstCard ? (
        <div styleName="hero-container">
          <h1 styleName="hero-title">Gifts & Benefits</h1>
          <img
            styleName="hero-img"
            src={`${BIRUBAL_GIFTING_NL_CLIP_BOX_SUBSCRIPTION}${COMPRESS_PARAMS_MEDIUM}`}
            alt="hero-image"
          />
        </div>
      ) : (
        <div styleName="container">
          <div styleName="img-container">
            <img
              src={`${get(gift, ["image"], "")}${COMPRESS_PARAMS_EXTRA_SMALL}`}
              alt="Birubala and Birubal"
            />
          </div>
          <h2 styleName="title">{get(gift, ["title"], "")}</h2>
          <ul styleName="list" aria-label="List of benefits">
            {items.length > 0 &&
              items.map((item, index) => {
                return (
                  <li styleName="list-item" key={index}>
                    {item.item}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
};

GiftCard.propTypes = {
  firstCard: PT.bool,
  gift: PT.object
};

export default GiftCard;
