import React from "react";
import PT from "prop-types";
import { UserAccountModal } from "../../molecules/account-modal";

import { WithMember } from "@quintype/components";

import { Login } from "../../molecules/forms/login";
import { SignUp } from "../../molecules/forms/sign-up";
import { OTP } from "../../molecules/forms/otp";
import { verifyEmail } from "../../helper/api";
import { ForgotPassword } from "../../molecules/forms/forgot-password";
import "./account-modal.m.css";

export class AccountModal extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTab: "login",
      member: null,
      otpTocken: null,
      error: {},
    };
  }

  changeTab(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  loginSuccess(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  register(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  setMember = (member) => {
    this.setState({ member });
  };

  otpHandler(member, otpDetails) {
    this.setState({
      member: member,
      activeTab: "otp",
      otpTocken: otpDetails["email-token"],
    });
  }

  onSuccess(member) {
    verifyEmail(member.email)
      .then((res) => this.otpHandler(member, res))
      .catch((error) => this.setState({ error: error }));
  }

  getScreen() {
    switch (this.state.activeTab) {
      case "login":
        return (
          <WithMember>
            {({ checkForMemberUpdated }) => {
              return (
                <Login
                  onLogin={(member, res) => this.otpHandler(member, res)}
                  checkForMemberUpdated={checkForMemberUpdated}
                  forgotPassword={() =>
                    this.setState({ activeTab: "forgot-password" })
                  }
                />
              );
            }}
          </WithMember>
        );
      case "register":
        return (
          <SignUp
            onSignup={(member) => this.onSuccess(member)}
            // checkForMemberUpdated={checkForMemberUpdated}
          />
        );
      case "otp":
        return (
          <WithMember>
            {({ checkForMemberUpdated }) => {
              return (
                <OTP
                  id={this.state.otpTocken}
                  member={this.state.member}
                  checkForMemberUpdated={checkForMemberUpdated}
                />
              );
            }}
          </WithMember>
        );
      case "forgot-password":
        return <ForgotPassword onBackdropClick={this.props.onBackdropClick} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <UserAccountModal onBackdropClick={this.props.onBackdropClick}>
        <div styleName="account-modal">
          <div styleName="form-wrapper">
            <ul styleName="tabs">
              <li
                onClick={() => this.changeTab("login")}
                styleName={`tab-item ${
                  this.state.activeTab === "login" ? "active" : ""
                }`}
              >
                Login
              </li>
              <li
                onClick={() => this.changeTab("register")}
                styleName={`tab-item ${
                  this.state.activeTab === "register" ? "active" : ""
                }`}
              >
                Register
              </li>
            </ul>
            <div className="forms">{this.getScreen()}</div>
          </div>
          {/* <div styleName="info" className="desktop-only">
            <h3 styleName="info-header">
              Pay to keep <br />
              <strong>news free</strong>
            </h3>
            <p styleName="info-text">
              Newslaundry wants to make news a service to the public, not to the
              advertisers. We believe those who make any effort sustainable,
              determine its direction. You decide.
            </p>
            <img
              styleName="info-image"
              src={assetify(subscriptionImage)}
              alt="subscription image"
            />
          </div> */}
        </div>
      </UserAccountModal>
    );
  }
}

AccountModal.propTypes = {
  onBackdropClick: PT.func,
  checkForMemberUpdated: PT.func,
};
