/* eslint-disable react/prop-types */
// /* eslint-disable max-len */
import React from "react";
import { Link, ResponsiveImage } from "@quintype/components";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { HiOutlineExternalLink } from "react-icons/hi";
import { FaFilePen, FaRetweet } from "react-icons/fa6";
import { FaDonate } from "react-icons/fa";

import { AiOutlineRetweet, AiOutlineFileSearch } from "react-icons/ai";
import { LiaDonateSolid } from "react-icons/lia";
import { LuFilePen } from "react-icons/lu";

import { FAQ, MEET_THE_EXPERTS_TEAM } from "./data";

import "./fight-to-breathe.m.css";

const StoryItem = ({ story }) => {
  return (
    <Link href={`${story.story.url}`} styleName="read-stories-grid-column">
      <figure className="qt-image-16x9" styleName="read-stories-grid-image">
        <ResponsiveImage
          slug={story.story["hero-image-s3-key"]}
          metadata={story.story["hero-image-metadata"]}
          aspectRatio={[16, 9]}
          defaultWidth={480}
          widths={[200, 480, 640]}
          sizes="(max-width: 500px) 25%, (max-width: 768px) 48%, 75%"
          imgParams={{ auto: ["format", "compress"] }}
        />
      </figure>
      <h2 styleName="read-stories-grid-title">{story.story.headline}</h2>
    </Link>
  );
};

const FightToBreathePage = (props) => {
  const stories = props.data.collection.items || [];
  const onlyEnglishStories = stories.filter(
    (s) => !s.story.url.includes("hindi.newslaundry.com")
  );
  const latestThreeStories = onlyEnglishStories.slice(4, 10);

  return (
    <div>
      <main styleName="hero-section">
        <div styleName="hero-section-div">
          <div styleName="hero-section-img">
            {/* <iframe
              styleName="iframeYT-mobile"
              width="350"
              height="200"
              src="https://www.youtube-nocookie.com/embed/ktUCtF9U2mo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <iframe
              styleName="iframeYT"
              width="464"
              height="261"
              src="https://www.youtube-nocookie.com/embed/UV5p0L8_S0U"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div styleName="hero-section-content">
            <h1 styleName="title">#FightToBreathe</h1>
            <p styleName="hero-description-small">
              At Newslaundry, we don’t usually do campaigns. But air pollution
              is a crisis, for every single one of us, young and old.
            </p>
            <p styleName="hero-description-small">
              So this time, we’re making an exception. This is our
              #FightToBreathe.
            </p>
            <div
              style={{ marginTop: "0.5rem" }}
              // styleName="standalone-link-wrapper"
            >
              <Link
                href="https://www.newslaundry.com/2024/12/23/air-pollution-is-an-existential-crisis-thats-why-we-are-making-an-exception"
                styleName="redirect-link"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </main>
      <section styleName="section-wrapper">
        <div styleName="section-wrapper-heading-wrapper">
          <h1 styleName="section-wrapper-heading">How can you join</h1>
          <div styleName="border-bottom"></div>
        </div>
        <div styleName="join-the-fight-grid">
          <div styleName="join-the-fight-grid-column">
            <AiOutlineFileSearch
              styleName="join-the-fight-grid-column-icon"
              aria-hidden="true"
            />
            <h3 styleName="join-the-fight-grid-column-title">The RTI Method</h3>
            <p styleName="join-the-fight-grid-column-description">
              File an RTI. The more questions you ask of public officials, the
              more information you get on what they are doing to address this
              crisis. Share the response to your RTI with us; it could be the
              basis for our next story.
            </p>
            <div styleName="standalone-link-wrapper">
              <a
                href="https://www.youtube.com/watch?v=UV5p0L8_S0U"
                target="_blank"
                rel="noreferrer"
                styleName="redirect-link"
              >
                Watch a how-to video
              </a>
            </div>
          </div>
          <div styleName="join-the-fight-grid-column">
            <AiOutlineRetweet
              styleName="join-the-fight-grid-column-icon"
              aria-hidden="true"
            />
            <h3 styleName="join-the-fight-grid-column-title">
              Repost with AQI
            </h3>
            <p styleName="join-the-fight-grid-column-description">
              Tweet about your area’s AQI with{" "}
              <b style={{ color: "var(--text-accent-muted)" }}>
                #FightToBreathe
              </b>
              .{" "}
              <a
                href="https://airquality.cpcb.gov.in/AQI_India/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "var(--text-accent-muted)",
                  textDecoration: "underline",
                }}
              >
                Here’s the link
              </a>{" "}
              to trace the nearest station in your state and town. If no centres
              are near you, specify that in your post using the same hashtag.
            </p>
            <div styleName="standalone-link-wrapper">
              <a
                styleName="redirect-link"
                href="https://twitter.com/intent/tweet?hashtags=FightToBreathe"
                target="_blank"
                rel="noreferrer"
              >
                Tweet with #FightToBreathe
              </a>
            </div>
          </div>
          <div styleName="join-the-fight-grid-column">
            <LiaDonateSolid
              styleName="join-the-fight-grid-column-icon"
              aria-hidden="true"
            />
            <h3 styleName="join-the-fight-grid-column-title">
              Power our campaign
            </h3>
            <p styleName="join-the-fight-grid-column-description">
              We are doing reports, videos, investigations, cartoons, a daily
              show, and expert collaborations. All this requires extensive
              resources. Click below to contribute an amount to your choice and
              be part of the fight.
            </p>
            <div styleName="standalone-link-wrapper">
              <a
                href="https://rzp.io/rzp/T4Ob0FCb"
                target="_blank"
                rel="noreferrer"
                styleName="redirect-link"
              >
                Contribute now
                <HiOutlineExternalLink
                  width="4px"
                  height="4px"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div styleName="standalone-link-wrapper">
          <p styleName="standalone-link-text">
            Do you have a story idea or a suggestion on how to make the campaign
            bigger? Drop us an email at{" "}
            <a
              href="mailto:submissions@newslaundry.com"
              style={{ color: "var(--text-accent-muted)" }}
            >
              submissions@newslaundry.com
            </a>
            .
          </p>
          <Link styleName="more-ways-to-contribute-button"></Link>
        </div>
      </section>
      <section styleName="gray-section-wrapper">
        <div styleName="gray-outer-div">
          <div styleName="section-wrapper-heading-wrapper">
            <h2 styleName="section-wrapper-heading">Meet the experts</h2>
            <div styleName="border-bottom"></div>
          </div>
          <p styleName="standalone-link-text" style={{ maxWidth: "100%" }}>
            As our advisory panel, they’ll guide our work and keep us on course.
          </p>
          <div styleName="meet-the-experts-grid">
            {MEET_THE_EXPERTS_TEAM.map((item, i) => (
              <div key={i} styleName="meet-the-experts-grid-column">
                <img
                  src={item.src}
                  alt={item.alt}
                  styleName="meet-the-experts-grid-column-image"
                />
                <p styleName="meet-the-experts-grid-column-name">{item.alt}</p>
                <p styleName="meet-the-experts-grid-column-designation">
                  {item.designation}
                </p>
              </div>
            ))}
          </div>
          <div styleName="standalone-link-wrapper">
            <Link
              href="2024/12/23/meet-the-experts-guiding-the-fighttobreathe"
              styleName="redirect-link"
            >
              Learn more
            </Link>
          </div>
        </div>
      </section>
      <section styleName="section-wrapper">
        <div styleName="section-wrapper-heading-wrapper">
          <h2 styleName="section-wrapper-heading">Latest stories</h2>
          <div styleName="border-bottom"></div>
        </div>
        <div styleName="read-stories-grid">
          {latestThreeStories.map((story) => (
            <StoryItem key={story.id} story={story} />
          ))}
        </div>
        <div styleName="standalone-link-wrapper">
          <Link href="nl-campaign/fight-to-breathe" styleName="redirect-link">
            Check out all stories
          </Link>
        </div>
      </section>
      <section styleName="section-wrapper">
        <div styleName="what-can-you-do-wrapper">
          <div styleName="what-can-you-do-content-wrapper">
            <div styleName="section-wrapper-heading-wrapper">
              <h2 styleName="section-wrapper-heading">#FightToBreathe FAQs</h2>
              <div styleName="border-bottom"></div>
            </div>
            <p styleName="standalone-link-text">
              Have a question that hasn’t been answered here? Write to us at{" "}
              <a
                href="mailto:submissions@newslaundry.com"
                style={{ color: "var(--text-accent-muted)" }}
              >
                submissions@newslaundry.com
              </a>
              .
            </p>
            <Accordion allowZeroExpanded>
              {FAQ.map((item, i) => (
                <AccordionItem key={i}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.question}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{item.answer}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
    </div>
  );
};

export { FightToBreathePage };
