import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import HorizontalStoryCard from "../horizontal-story-card";
import CommonStoryCard from "../common-story-card";
import { LoadMoreButton } from "../../atoms/load-more-button";
import { LoadMoreStoriesBase } from "@quintype/components";
import Trending from "../trending";
import getStoriesByCollection from "../../../utils";
import { storyFields } from "../../../constants";
import "./common-list-page.m.css";

const CommonListPage = ({ data = {}, hideAuthorWithTime = false }) => {
  const trendingStories = getStoriesByCollection(
    get(data, ["trendingCollection", "collection"], {})
  );

  const AuthorPageUI = ({ stories, onLoadMore, noMoreStories }) => (
    <div>
      <div className="desktop-only">
        {stories.map(story => (
          <HorizontalStoryCard
            story={story}
            key={story.id}
            boxPadding={false}
            showSection
            increaseFontSize
          />
        ))}
      </div>
      <div className="mobile-only" styleName="card-padding">
        {stories.map(story => (
          <CommonStoryCard story={story} key={story.id} showSection />
        ))}
      </div>
      {data.stories.length >= 10 && !noMoreStories && (
        <div styleName="load-more">
          <LoadMoreButton onClick={onLoadMore} />
        </div>
      )}
    </div>
  );

  return (
    <div styleName="wrapper">
      {data.stories.length > 0 ? (
        <LoadMoreStoriesBase
          template={AuthorPageUI}
          fields={storyFields}
          api={`${data.baseApi}`}
          data={data}
          numStoriesToLoad={19}
        />
      ) : (
        <div>No Results Found</div>
      )}
      <div className="desktop-only">
        <Trending
          stories={trendingStories}
          collection={get(data, ["trendingCollection", "collection"], {})}
          hideAuthorWithTime={hideAuthorWithTime}
        />
      </div>
    </div>
  );
};

CommonListPage.propTypes = {
  data: PropTypes.shape({
    stories: PropTypes.array
  }),
  stories: PropTypes.object,
  hideAuthorWithTime: PropTypes.bool,
  noMoreStories: PropTypes.bool,
  onLoadMore: PropTypes.func
};
export default CommonListPage;
